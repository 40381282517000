/* Angular Components */
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, SimpleChanges } from '@angular/core';
import { TableModule } from 'primeng/table';
import { tableCol } from 'src/app/common/table-cols';

/* App Components */

/* App Services */
import { Caja_Service } from 'src/app/services/gestion-caja/gestion_caja.service';

@Component({
	selector: 'app-resumen-movimiento-caja',
	standalone: true,
	imports: [TableModule, CommonModule],
	templateUrl: './resumen-movimiento-caja.component.html',
	styleUrl: './resumen-movimiento-caja.component.css',
	providers: [Caja_Service]
})
export class ResumenMovimientoCajaComponent {
	/* Component Data */
	@Input() RowData_Parent: any;
	ResumenMovimiento_Caja: any = {};
	Movimientos: any[] = [];
	Ingresos: any[] = [];
	Egresos: any[] = [];
	id_caja: any;
	formData: any = {};

	cols: tableCol[] = [
		{ field: 'tipo', header: 'tipo' },
		{ field: 'tipo_concepto', header: 'tipo_concepto' }
	  ];

	/* Child Component Config */


	/* Component Functions */
	constructor(
		private _Caja_Service: Caja_Service,
		private changeDetectorRef:ChangeDetectorRef
	) { }
	ngOnChanges(changes: SimpleChanges) {
		if (changes['RowData_Parent'] && changes['RowData_Parent'].currentValue) {
			this.formData = { ...changes['RowData_Parent'].currentValue };
			this.id_caja = this.formData.id_caja;

			if (this.id_caja) {
				this.Show_ResumenMovimientosCaja();
			}
		}
		this.changeDetectorRef.detectChanges(); // Forzar actualización
	}

	/* App Logical */
	Show_ResumenMovimientosCaja(): void {
		if (!this.id_caja) {
			console.error('ID de caja no definido');
			return;
		}

		const request = {
			'resumen-with-movimiento': this.id_caja
		};

		this._Caja_Service.getByOther(request).subscribe({
			next: (response: any) => {
				if (response.status === 'OK') {
					this.ResumenMovimiento_Caja = response.data;
					this.Movimientos = this.ResumenMovimiento_Caja.movimientos;
					this.Ingresos = this.ResumenMovimiento_Caja.movimientos.ingresos;
					this.Egresos = this.ResumenMovimiento_Caja.movimientos.egresos;
					console.log(this.Ingresos);
				} else {
					console.error('No se encontró ningún dato');
				}
			},
			error: (error) => {
				console.error('Error al consumir el servicio', error);
			},
		});
	}
}
