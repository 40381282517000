import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { Subject } from 'rxjs';
import { CuentaById_Service } from 'src/app/services/gestion-cuenta/cuenta_by_id.service';
import { RegistrarMovimientoCuentaComponent } from '../registrar-movimiento-cuenta/registrar-movimiento-cuenta.component';
import { PeriodoService } from 'src/app/services/general-services/periodo.service';
import { Cuenta_Service } from 'src/app/services/gestion-cuenta/gestion-cuenta.service';
import { ResumenCuentaPeriodoComponent } from '../resumen-cuenta-periodo/resumen-cuenta-periodo.component';

@Component({
	selector: 'app-detalle-cuenta-banc',
	standalone: true,
	imports: [FormsModule,
		CommonModule,
		DropdownModule,
		InputTextModule,
		ButtonModule,
		DialogModule,
		RegistrarMovimientoCuentaComponent,

		FloatLabelModule,
		CardModule,
		TableModule,
		ResumenCuentaPeriodoComponent
	],
	templateUrl: './detalle-cuenta-banc.component.html',
	styleUrl: './detalle-cuenta-banc.component.css',
	providers: [
		PeriodoService
	]
})
export class DetalleCuentaBancComponent {
	@Input() data: any; // Recibe los datos del componente padre
	Movimientos: any[] = [];
	formData: any = {};
	id_cuenta: any;
	Periodos: any[] = [];
	id_periodo: any = {};

	DetallesCuent_Response: any;

	Display_Modal_Registrar_MovimientoCuenta: boolean = false;

	Display_Modal_Registrar_MovimientoCuenta1: boolean = false;

	Display_Modal_Ver_ResumenCuenta: boolean = false;
	Display_Modal_Registrar_Cuenta: boolean = false;

	@Input() moviemineto_cuenta_Response: any[] = [];

	ngOnChanges(changes: SimpleChanges) {
		if (changes['data'] && changes['data'].currentValue) {
			this.formData = { ...changes['data'].currentValue };


			this.id_cuenta = this.formData.id_cuenta;
			if (this.id_cuenta) {
				this.Show_Detallescuenta();
			}
		}
	}

	eventsSubject: Subject<void> = new Subject<void>();

	/* Component Functions */
	constructor(
		private _cuentaByID: CuentaById_Service,
		private _Cuenta_Service: Cuenta_Service,
		private _Periodo_Service: PeriodoService

		// private changeDetectorRef: ChangeDetectorRef
	) { }
	ngOnInit(): void {
		// this.Show_DetallesCaja();
		this.Get_Periodos_Api();
	}

	Get_Periodos_Api(): void {
		this._Periodo_Service.get().subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.Periodos = response_data;
					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se enconrtó datos');
				}
			},
			error: (error) => {
				console.log("Error al consumir el servicio", error);
			}
		});

	}


	Show_Detallescuenta(): void {
		if (!this.id_cuenta) {
			console.error('ID de cuenta no definido');
			return;
		}

		const request = {
			'cuenta-with-movimiento': this.id_cuenta
		};

		this._cuentaByID.getByOther(request, 'general/').subscribe({
			next: (response: any) => {
				if (response.status === 'OK') {
					this.DetallesCuent_Response = response.data;
					this.Movimientos = this.DetallesCuent_Response.movimientos;
				} else {
					console.error('No se encontró ningún dato');
				}
			},
			error: (error) => {
				console.error('Error al consumir el servicio', error);
			},
		});
	}

	Get_Movimientos_By_Periodo ():void {
		const request = {
			'cuenta-with-movimiento': this.id_cuenta,
			'periodo': this.id_periodo
		}
		this._Cuenta_Service.getByOther(request, 'general/').subscribe({
			next: (response:any) => {
				if (response.status == 'OK') {
					this.Movimientos = response.data.movimientos;
				} else {
					console.log('Error al consumir el servicio');
				}
			}
		})
	}




	Open_Modal_Registrar_MovimientoCaja(): void {
		
		this.Display_Modal_Registrar_Cuenta = true;
	}

	

	Open_Modal_Ver_ResumenCuenta( formData : any ): void {
		this.Display_Modal_Ver_ResumenCuenta = true;
		this.formData = formData;
	}

	Close_Modal() {
		this.Display_Modal_Registrar_Cuenta = false;
	}
	

	hideDialog2() {
		this.Display_Modal_Registrar_MovimientoCuenta1 = false;
	}

	hideDialog() {
		this.Display_Modal_Registrar_MovimientoCuenta = false;
	}
	ActualizarRegistroAgregado(nuevoRegistro: any): void {
		this.Movimientos.push(nuevoRegistro);
		this.Movimientos = [...this.Movimientos]; // Forzar la actualización de la vista
		this.hideDialog();
	}
}
