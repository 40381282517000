import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { Subject } from 'rxjs';
import { alert_error, alert_success } from 'src/app/functions/alert-function';
import { Caja_Request } from 'src/app/request/gestion-caja/caja-request';
import { Empresa_Service } from 'src/app/services/general-services/empresa.service';
import { Moneda_Service } from 'src/app/services/general-services/moneda.service';
import { Sede_Service } from 'src/app/services/general-services/sede.service';
import { Caja_Service } from 'src/app/services/gestion-caja/gestion_caja.service';

@Component({
	selector: 'app-registrar-caja',
	standalone: true,
	templateUrl: './registrar-caja.component.html',
	styleUrls: ['./registrar-caja.component.css'],
	imports: [ReactiveFormsModule, DropdownModule, CalendarModule, CardModule, FontAwesomeModule, InputNumberModule, InputTextModule, ButtonModule, CommonModule]
})
export class RegistrarCajaComponent {
	@Input() title: string = "";

	@Output() Close_Modal_RegistrarCaja_Event = new EventEmitter<any>();
	@Output() registroAgregado = new EventEmitter<any>();
	form: FormGroup;
	errors: any = {};
	Empresas: any[] = [];
	Sedes: any[] = [];
	Monedas: any[] = [];

	constructor(
		private fb: FormBuilder,
		private _Empresa_Service: Empresa_Service,
		private _Sede_Service: Sede_Service,
		private _Moneda_Service: Moneda_Service,
		private _Caja_Service: Caja_Service
	) {
		this.form = this.fb.group({
			empresa_id: [null, Validators.required],
			sede_id: [null, Validators.required],
			nombre: ['', Validators.required],
			nombre_corto: [''],
			nombre_largo: [''],
			detalles: [''],
			fecha_inicio: ['', Validators.required],
			fecha_fin: ['', Validators.required],
			moneda: ['', Validators.required],
			simbolo_moneda: ['', Validators.required],
			monto_inicial: ['', [Validators.required, Validators.pattern('^\\d+(\\.\\d{2,})?$')]]
		});

		// Limpia los errores al cambiar el valor en los campos del formulario
		this.limpiar_validacion_escribir();
	}

	ngOnInit(): void {
		this.Get_Empresas_Api();
		this.Get_Monedas_Api();
	}


	

	limpiar_validacion_escribir(): void {
		Object.keys(this.form.controls).forEach(key => {
			this.form.get(key)?.valueChanges.subscribe(() => {
				this.errors[key] = null; // Limpia el error cuando el usuario empieza a escribir
			});
		});
	}

	Get_Empresas_Api(): void {
		this._Empresa_Service.get().subscribe({
			next: (response: any) => {
				this.Empresas = response.data || [];
			},
			error: (error) => console.log("Error al consumir el servicio", error)
		});
	}

	Get_Sedes_By_EmpresaId_Api(object: DropdownChangeEvent): void {
		this._Sede_Service.getByOther({ 'empresa-id': object.value }).subscribe({
			next: (response: any) => {
				this.Sedes = response.data || [];
			},
			error: (error) => console.log("Error al consumir el servicio", error)
		});
	}

	Get_Monedas_Api(): void {
		this._Moneda_Service.get().subscribe({
			next: (response: any) => {
				this.Monedas = response.data || [];
			},
			error: (error) => console.log("Error al consumir el servicio", error)
		});
	}

	closeModal(res: boolean): void {
		this.Close_Modal_RegistrarCaja_Event.emit(res);
	}

	hideDialog() {
		//  this.form.reset();
		this.Close_Modal_RegistrarCaja_Event.emit();
	  }

	Register_Caja_APi(): void {
		if (this.form.invalid) {
			this.form.markAllAsTouched(); // Marca todos los campos como tocados para mostrar errores
			return;
		  }

		this._Caja_Service.create(this.form.value).subscribe({
			next: (response: any) => {
				if (response.status === 'OK') {
					alert_success("El registro se realizó de forma exitosa.");
					this.registroAgregado.emit();
					this.Close_Modal_RegistrarCaja_Event.emit(); 
				} else {
					alert_error('ERROR', 'Ocurrió un error al consumir el servicio');
				}
				this.Close_Modal_RegistrarCaja_Event.emit();
			},
			error: (err) => {
				console.error("Error al registrar el producto:", err);
				this.Close_Modal_RegistrarCaja_Event.emit();
			}
		});
	}
	getErrorMessage(controlName: string): string {
		const control = this.form.get(controlName);
		if (control!.hasError('required')) {
		  return 'Este campo es obligatorio';
		}
		if (control!.hasError('pattern')) {
		  return 'Formato no válido';
		}
		return '';
	  }
}
