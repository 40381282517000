import { Injectable } from '@angular/core';


import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { CrudService } from '../util/crud.service';
import { behaidorInterface } from '../interfaces/behaidor-interface';
import { urlConstants } from 'src/app/constants/url-constants';

@Injectable({
  providedIn: 'root'
})
export class UpdateEstadoDesembolsoService 
extends CrudService<any, any>
implements behaidorInterface<any[]> {

  private dataSource = new BehaviorSubject<any[]>([]);

	constructor(protected http: HttpClient) {
		super(http, urlConstants.update__estado_desembolso);
	}

	setValueBehaidor(value: any[]): void {
		this.dataSource.next(value);
	}
	getValueBehaidorSubject() {
		return this.dataSource.getValue();
	}
	clearBehaidorSubject(): void {
		this.dataSource.next([]);
	}
}
