<div class="card">
    
        <p-menubar [model]="items">
            <ng-template pTemplate="start">
                <img  src="assets/images/logo/my-logo.png" height="40" class="mr-2" />
            </ng-template>
            <ng-template pTemplate="end" >
                <div class="c--usuario-container">
                    <label class="c--usuario"> {{ user }} <br> </label>
                </div>
                <p-button label="Cerrar Sesión"  (click)="logout()" severity="danger"></p-button>

            </ng-template>
        </p-menubar>
    
</div>