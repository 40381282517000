import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-page-not-found',
  templateUrl: './admin-page-not-found.component.html',
  styleUrls: ['./admin-page-not-found.component.css']
})
export class AdminPageNotFoundComponent {

}
