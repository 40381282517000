<div class="card flex gap-2" id="carta_1">
	<div class="w-9 mb-5">
		<div class="px-gutter pt-6 pb-20 bg-slate-900 flex-none">
			<div class="flex items-center justify-between" vexContainer>
				<h2 class="headline text-neutral-100 m-0 flex items-center w-full max-w-xxxl mr-6">
					<fa-icon [icon]="faTableList" class="hidden sm:block ml-5 fa-2xl"></fa-icon>
					<span class="ml-4 block pt-0">Reporte Desembolso</span>
				</h2>
			</div>
		</div>
	</div>
	<div class="container-fluid">
		<div class="card text-dark bg-light mb-3">
			<div class="card-body">
				<!-- Tabla paginada -->
				<p-table #dt2 [value]="Desembolso_Response" dataKey="id" [rows]="10"
					[rowsPerPageOptions]="[10, 25, 50]" [loading]="loading" [paginator]="true"
					[globalFilterFields]="['nombre_empresa', 'nro_cuenta__recaudadora', 'nombre_periodo', 'persona_solicita', 'estado', 'detalles_estado']"
					[tableStyle]="{ 'min-width': '75rem' }">

					<ng-template pTemplate="caption">
						<div class="flex">
							<p-iconField iconPosition="left" class="ml-auto">
								<p-inputIcon>
									<i class="pi pi-search"></i>
								</p-inputIcon>

								<input pInputText #textInput type="text"
									(input)="dt2.filterGlobal(textInput.value, 'contains')"
									placeholder="Global Search" />


							</p-iconField>
						</div>
					</ng-template>

					<ng-template pTemplate="header">
						<tr>
							<th *ngFor="let col of cols" [ngClass]="col.field === 'estado' ? 'estado-col-header' : ''">
								{{ col.header }}
							</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-rowData>
						<tr>
							<td *ngFor="let col of cols" [ngClass]="col.field === 'estado' ? 'estado-col' : ''">
								<span [ngClass]="col.field === 'estado' ? getEstadoClass(rowData[col.field]) : ''">
									{{ rowData[col.field] }}
								</span>
							</td>
							<td>
								<p-splitButton label="ESTADO"
								appendTo="body"
									[model]="getOptionsEstado_Button(rowData)"></p-splitButton>
							</td>
						</tr>
					</ng-template>
				</p-table>
			</div>
		</div>
	</div>

	<p-dialog header="Actualizar estado del desembolso"  [draggable]="false" [resizable]="false" [(visible)]="displayModal" modal="true" [closable]="false">
		<app-update-estado-desembolso [DataComponent_Parent]="rowData" (Close_Modal_Event)="Close_Modal()"
			(registrosAgregado)="ActualizarEstadoAgregado($event)"></app-update-estado-desembolso>
	</p-dialog>

	<ng-template #ConfigUser></ng-template>
</div>