export class Cuenta_Response {
  empresa_id: number | null;
  sede_id?: number | null;
  persona_id: number | null;
  nombre: string;
  nombre_corto: string;
  nombre_largo: string;
  titular: string;
  tipo_cuenta: string;
  banco: string;
  moneda: string;
  simbolo_moneda: string;
  nro_cuenta: string;
  nro_cci: string;
  monto_inicial: string;
  detalles: string;

  constructor() {
    this.empresa_id = 0;
    this.sede_id = 0;
    this.persona_id = 0;
    this.nombre = '';
    this.nombre_corto = '';
    this.nombre_largo = '';
    this.titular = '';
    this.tipo_cuenta = '';
    this.banco = '';
    this.moneda = '';
    this.simbolo_moneda = '';
    this.nro_cuenta = '';
    this.nro_cci = '';
    this.monto_inicial = '';
    this.detalles = '';
  }
}
