<p-toast></p-toast>
<div class="modal-header">
    <h4 id="h41" class="modal-title pull-left">{{ title }}</h4>
</div>

<p-card>
    <div class="header-boton">
       

        <div class="select-1">
            <p>Desembolso:</p>
            <p-dropdown [showClear]="true" placeholder="Selecciona Desembolso" [options]="desembolsos"
                optionValue="id_desembolso" optionLabel="id_desembolso" [(ngModel)]="desembolso.id_desembolso"
                (onChange)="Get_Desembolso()"></p-dropdown>
        </div>
        <div class="select-1">
            <p>Monto Desembolso:</p>
            <input type="text" class="input-size" pInputText [(ngModel)]="desembolso.monto" [readOnly]="true"/>
        </div>
        <div class="select-1">
            <p>Gasto Desembolso:</p>
            <input type="text" class="input-size" pInputText [(ngModel)]="desembolso.gasto_desembolso" [readOnly]="true"/>
        </div>
        <div class="select-1">
            <p>Saldo Desembolso:</p>
            <input type="text" class="input-size" pInputText [(ngModel)]="desembolso.saldo_desembolso" [readOnly]="true"/>
        </div>
        <div class="select-1">
            <p>Detalles:</p>
            <input type="text" class="input-size" pInputText [(ngModel)]="desembolso.detalles" [readOnly]="true"/>
        </div>

        <div class="botton-1">
            <p-button icon="pi pi-plus" (click)="agregarFila()" label="Agregar"></p-button>
        </div>
    </div>
</p-card>

<p-divider type="solid"></p-divider>

<p-card>
    <table>
        <thead>
            <tr>
                <th width="20%">Concepto</th>
                <th width="10%">Fecha</th>
                <th width="10%">Tipo Comprobante</th>
                <th width="5%">N° Comprobante</th>
                <th width="5%">S/Moneda</th>
                <th width="5%">Moneda</th>
                <th width="8%">Monto</th>
                <th width="8%">Comprobante</th>
                <th width="8%">Detalles</th>
                <th width="5%">Acciones</th> <!-- Añadido para acciones -->
            </tr>
        </thead>
        <tbody #contenedorInputs>
            <tr *ngFor="let detalle_liquidacion of detalles_liquidacion_gastos; let i = index">
                <td>
                    <input type="text" class="input-size" pInputText [(ngModel)]="detalle_liquidacion.concepto" />
                </td>
                <td>
                    <p-calendar [showTime]="false" [(ngModel)]="detalle_liquidacion.fecha_comprobante" dateFormat="yy/mm/dd" appendTo="body"></p-calendar>
                </td>
                <td>
                    <p-dropdown [options]="Tipo_comprobante" [(ngModel)]="detalle_liquidacion.tipo_comprobante"></p-dropdown>
                </td>
                <td>
                    <input class="input-size" type="text" pInputText [(ngModel)]="detalle_liquidacion.nro_comprobante" />
                </td>
                <td>
                    <p-dropdown [options]="monedas" optionValue="id_moneda" optionLabel="simbolo" [(ngModel)]="detalle_liquidacion.simbolo_moneda"></p-dropdown>
                </td>
                <td>
                    <p-dropdown [options]="monedas" optionValue="id_moneda" optionLabel="nombre" [(ngModel)]="detalle_liquidacion.moneda"></p-dropdown>
                </td>
                <td>
                    <input class="input-size monto-detalle-liquidacion" type="number" pInputText [(ngModel)]="detalle_liquidacion.monto" (keyup)="Calculate_GastoTotal()" />
                </td>
                <td>
                    <input type="text" class="input-size" pInputText [(ngModel)]="detalle_liquidacion.url_comprobante" />
                </td>
                <td>
                    <input type="text" class="input-size" pInputText [(ngModel)]="detalle_liquidacion.detalles" />
                </td>
                <td>
                    <p-button [raised]="true" pTooltip="Eliminar" tooltipPosition="bottom" icon="pi pi-trash" (click)="eliminarFila(i)" severity="danger"></p-button>
                </td>
            </tr>
        </tbody>
    </table>
</p-card>

<p-divider type="solid"></p-divider>

<p-card>
    <div class="register-botton">
        <button pButton type="button" class="p-button-success mb-5" icon="pi pi-save" label="Registrar" (click)="registerLiquidacionGastos()"></button>
        <p-button label="Cancelar" (click)="hideDialog()" [raised]="true" severity="danger"></p-button>
    </div>
</p-card>
