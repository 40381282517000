import { Injectable } from '@angular/core';


import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';



import { CrudService } from '../util/crud.service';
import { behaidorInterface } from '../interfaces/behaidor-interface';
import { urlConstants } from 'src/app/constants/url-constants';



import { Liqu_gastos_Response } from 'src/app/response/gestion-desembolso/liquidacion-gastos-response';
import { Liqu_gastos_Request } from 'src/app/request/gestion-desembolso/liquidacion-gastos.request';
import { Programar_Desembolso_Response } from 'src/app/response/gestion-desembolso/programar-desembolso-response';
import { Programar_Desembolso_Request } from 'src/app/request/gestion-desembolso/programar-desembolso-request';

@Injectable({
	providedIn: 'root',
})
export class Progrmacion_Desembolso_Service
	extends CrudService<Programar_Desembolso_Request, Programar_Desembolso_Response>
	implements behaidorInterface<Programar_Desembolso_Response[]>
{
	private dataSource = new BehaviorSubject<Programar_Desembolso_Response[]>([]);

	constructor(protected http: HttpClient) {
		super(http, urlConstants.progrmar_desembolso);
	}

	setValueBehaidor(value: Programar_Desembolso_Response[]): void {
		this.dataSource.next(value);
	}
	getValueBehaidorSubject() {
		return this.dataSource.getValue();
	}
	clearBehaidorSubject(): void {
		this.dataSource.next([]);
	}
}
