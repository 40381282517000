import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { Subject } from 'rxjs';
import { alert_error, alert_success } from 'src/app/functions/alert-function';
import { Area_Service } from 'src/app/services/general-services/area.service';
import { Banco_Service } from 'src/app/services/general-services/banco.service';
import { DataService } from 'src/app/services/general-services/data.service';
import { Empresa_Service } from 'src/app/services/general-services/empresa.service';
import { Moneda_Service } from 'src/app/services/general-services/moneda.service';
import { Persona_Service } from 'src/app/services/general-services/persona.service';
import { Sede_Service } from 'src/app/services/general-services/sede.service';
import { Cuenta_Service } from 'src/app/services/gestion-cuenta/gestion-cuenta.service';

@Component({
  selector: 'app-registrar-cuenta-banc',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DropdownModule,
    FormsModule,
    CalendarModule,
    CardModule,
    FontAwesomeModule,
    InputNumberModule,
    CommonModule,
    InputTextModule,
    ButtonModule,
  ],
  templateUrl: './registrar-cuenta-banc.component.html',
  styleUrl: './registrar-cuenta-banc.component.css',
})
export class RegistrarCuentaBancComponent {
  //////////////variables-iputs-outputs//////////////
  @Input() title: string = '';
  tipoCuentas: any[] = [];
  selectedTipoCuenta: string;
  @Output() Close_Modal_Event2 = new EventEmitter<boolean>();
  
  @Output() registroAgregado = new EventEmitter<any>();
  //////////////eventos-respons//////////////
  eventsSubject: Subject<void> = new Subject<void>();
  form: FormGroup;
  errors: any = {};
  //////////////constructor-init//////////////

  constructor(
    private _empresaService: Empresa_Service,
    private _bancoService: Banco_Service,
    private fb: FormBuilder,
    private _monedaService: Moneda_Service,
    private _cuentaSerive: Cuenta_Service,
    private _sedeService: Sede_Service,
    private _areaService: Area_Service,
    private _peronsaService: Persona_Service,
    private dataService: DataService
  ) {
    this.form = this.fb.group({
      empresa_id: [null, Validators.required],
      sede_id: [null, Validators.required],
      persona_id: [null, Validators.required],
      nombre: ['', Validators.required],
      nombre_corto: ['', Validators.required],
      nombre_largo: ['', Validators.required],
      titular: ['', Validators.required],
      tipo_cuenta: ['', Validators.required],
      banco: ['', Validators.required],
      moneda: ['', Validators.required],
      simbolo_moneda: ['', Validators.required],

      nro_cuenta: ['', Validators.required],
      nro_cci: ['', Validators.required],
      monto_inicial: [
        '',
        [Validators.required, Validators.pattern('^\\d+(\\.\\d{2,})?$')],
      ],
      detalles: ['', Validators.required],
    });

    // Limpia los errores al cambiar el valor en los campos del formulario
    this.limpiar_validacion_escribir();
  }

  ngOnInit(): void {
    this.tipoCuentas = [
      { id: 'CUENTA CORRIENTE', nombre: 'Cuenta Corriente' },
      { id: 'CUENTA AHORRO', nombre: 'Cuenta de Ahorro' },
      { id: 'otra', nombre: 'Otra' },
    ];
    this.getEmpresas_Api();
    this.getBanco_Api();
    this.getMoneda_Api();
    this.getPersonas_Api();
  }

  //////////////datos dropwdop///////////
  sedes: any[] = [];
  empresas: any[] = [];
  bancos: any[] = [];
  monedas: any[] = [];
  areas: any[] = [];
  personas: any[] = [];
  cuenta: any = {};
  tipo_cuenta1: any = {};
  persona: any = {};
  empresa: any = {};
  sede: any = {};

  limpiar_validacion_escribir(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key)?.valueChanges.subscribe(() => {
        this.errors[key] = null; // Limpia el error cuando el usuario empieza a escribir
      });
    });
  }

  //////////////get all monedas//////////////
  getMoneda_Api(): void {
    this._monedaService.get().subscribe({
      next: (response: any) => {
        const response_data = response.data;
        if (response_data) {
          this.monedas = response_data;
          setTimeout(() => {
            this.eventsSubject.next();
          }, 200);
        } else {
          console.error('No se encontraron datos');
        }
      },
      error: (error) => {
        console.log('Error al consumir el servicio', error);
      },
    });
  }
  //////////////get-sedes-empresa//////////////

  Get_SedesByEmpresaId_Api(event: DropdownChangeEvent): void {
    const empresaId = event.value;
    if (!empresaId) {
        return;
    }

    this._sedeService.getByOther({ 'empresa-id': empresaId }).subscribe({
        next: (response: any) => {
            this.sedes = response.data || [];
            this.form.get('sede_id')?.setValue(null); // Resetea el valor del FormControl
        },
        error: (error) => {
            console.error('Error al obtener sedes:', error);
        }
    });
}
  
  
  //////////////get all personas//////////////
  getPersonas_Api(): void {
    this._peronsaService.get().subscribe({
      next: (response: any) => {
        const response_data = response.data;
        if (response_data) {
          this.personas = response_data;
          setTimeout(() => {
            this.eventsSubject.next();
          }, 200);
        } else {
          console.error('No se encontraron datos');
        }
      },
      error: (error) => {
        console.log('Error al consumir el servicio', error);
      },
    });
  }
  //////////////get all areas - sedes//////////////
  Get_AreasBySedeId_Api(event: any): void {
   
  
    let sede_id = event?.value;
     
  
    let request = {
      'sede-id': sede_id,
    };
  
    // Realiza la llamada al servicio
    this._areaService.getByOther(request).subscribe({
      next: (response: any) => {
        const response_data = response.data;
        if (response_data) {
          this.areas = response_data;
          setTimeout(() => {
            this.eventsSubject.next();
          }, 200);
        } else {
          console.error('No se encontraron datos');
        }
      },
      error: (error) => {
        console.log('Error al consumir el servicio', error);
      },
    });
  }
  
  
  
  
  //////////////get all empresas//////////////
  getEmpresas_Api(): void {
    this._empresaService.get().subscribe({
        next: (response: any) => {
            this.empresas = response.data || [];
        },
        error: (error) => {
            console.error('Error al obtener empresas:', error);
        },
    });
}
  //////////////get all - bancos//////////////
  getBanco_Api(): void {
    this._bancoService.get().subscribe({
      next: (response: any) => {
        const response_data = response.data;
        if (response_data) {
          this.bancos = response_data;
          setTimeout(() => {
            this.eventsSubject.next();
          }, 200);
        } else {
          console.error('No se encontraron datos');
        }
      },
      error: (error) => {
        console.log('Error al consumir el servicio', error);
      },
    });
  }
  //////////////post register cuenta bancaria//////////////
  registerCuentaBan(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched(); // Marca todos los campos como tocados para mostrar errores
      return;
    }
  
    this._cuentaSerive.create(this.form.value).subscribe({
      next: (response: any) => {
        alert_success('Registrado de forma correcta', 2000);
        this.registroAgregado.emit();
        this.Close_Modal_Event2.emit(); 
      },
      error: (err) => {
        console.error('Error al registrar', err);
      },
    });
  }
  
  //////////////close modlas//////////////
  hideDialog() {
    //  this.form.reset();
    this.Close_Modal_Event2.emit();
  }

 

  getErrorMessage(controlName: string): string {
    const control = this.form.get(controlName);
    if (control!.hasError('required')) {
      return 'Este campo es obligatorio';
    }
    if (control!.hasError('pattern')) {
      return 'Formato no válido';
    }
    return '';
  }
  
}
