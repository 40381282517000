import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor() { }
  
  sessionStorageSetItem(name:string, data:string)
  {
    sessionStorage.setItem(name, data);
  }

  sessionStorageGetItem(name:string)
  {
    let value =  sessionStorage.getItem(name);
    
    return value;
  }
}
