import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CardModule } from 'primeng/card';
import {
  Dropdown,
  DropdownChangeEvent,
  DropdownModule,
} from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  Validators,
} from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { DividerModule } from 'primeng/divider';
import { CommonModule } from '@angular/common';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CalendarModule } from 'primeng/calendar';

import { Subject, Subscription, empty } from 'rxjs';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTableList } from '@fortawesome/free-solid-svg-icons';
import {
  alert_error,
  alert_success,
  alert_warning,
} from 'src/app/functions/alert-function';
import { DialogModule } from 'primeng/dialog';

import { InputNumberModule } from 'primeng/inputnumber';
import { Unidadmedida } from 'src/app/common/unidad_medida';
import { Requerimiento_Pago_Service } from 'src/app/services/gestion-desembolso/registro-req-pago.service';
import { Empresa_Service } from 'src/app/services/general-services/empresa.service';
import { Sede_Service } from 'src/app/services/general-services/sede.service';
import { Persona_Service } from 'src/app/services/general-services/persona.service';
import { Moneda_Service } from 'src/app/services/general-services/moneda.service';
import { Tipo_Requerimiento_Request } from 'src/app/request/generalRequest/tipoRequerimiento-request';
import { Tipo_Requerimiento_Service } from 'src/app/services/general-services/tipoRequerimiento.service';
import { Banco_Service } from 'src/app/services/general-services/banco.service';
import { CommunicationService } from 'src/app/services/general-services/comunicacion.service';
import { Area_Service } from 'src/app/services/general-services/area.service';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-registrar-req-pago',
  standalone: true,
  imports: [
    CardModule,
    InputNumberModule,
    TableModule,
    DropdownModule,
    CalendarModule,
    ButtonModule,
    FormsModule,
    InputTextModule,
    TooltipModule,
    DividerModule,
    CommonModule,
    SelectButtonModule,
    FontAwesomeModule,
    DialogModule,
    ToastModule,
    FormsModule,
  ],
  templateUrl: './registrar-req-pago.component.html',
  styleUrl: './registrar-req-pago.component.css',
  providers: [MessageService],
})
export class RegistrarReqPagoComponent {
  ////parametros de entrada//////////
  visible: boolean = true;
  faTableList = faTableList;
  display: boolean = false; // Controla la visibilidad del diálogo
  @Input() title: string = '';
  @Output() closeModalEmmit = new EventEmitter<boolean>();
  @Output() Close_Modal_Event3 = new EventEmitter<void>();
  @Output() registroAgregado = new EventEmitter<any>();

  disableButton: boolean = false;
  requerimientoPago_Form: FormGroup;
  Button_Disabled: boolean = false;
  eventsSubject: Subject<void> = new Subject<void>();
  formVisible = false;

  //////////////datos dropwdop///////////

  empresas: any[] = [];
  sedes: any[] = [];
  areas: any[] = [];
  personas: any[] = [];
  monedas: any[] = [];
  tipos_concepto: any[] = [];
  tipos_pago: any[] = [
    {
      id_tipo_pago: 1,
      nombre: 'FÍSICO',
    },
    {
      id_tipo_pago: 2,
      nombre: 'DIGITAL',
    },
  ];
  metodos_pago: any[] = [
    {
      id_tipo_pago: 1,
      nombre: 'YAPE',
    },
    {
      id_tipo_pago: 2,
      nombre: 'TRANSFERENCIA',
    },
    {
      id_tipo_pago: 2,
      nombre: 'EFECTIVO',
    },
  ];
  tipos_comprobante: any[] = [
    {
      id_tipo_pago: 1,
      nombre: 'FACTURA',
    },
    {
      id_tipo_pago: 2,
      nombre: 'BOLETA DE PAGO',
    },
  ];
  bancos: any[] = [];

  constructor(
    private fb: FormBuilder,
    private _reque_pago_service: Requerimiento_Pago_Service,
    private _empresaService: Empresa_Service,
    private _sedeService: Sede_Service,
    private _areaService: Area_Service,
    private _peronsaService: Persona_Service,
    private _monedaService: Moneda_Service,
    private _tipoRequerimientoService: Tipo_Requerimiento_Service,
    private _bancoService: Banco_Service,
    private messageService: MessageService
  ) {
    this.requerimientoPago_Form = this.fb.group({
      // almacen_id: [null, [Validators.required]],
      // proveedor_id: [null, [Validators.required]],
      // productos: [],
    });
  }

  get f() {
    return this.requerimientoPago_Form.controls;
  }

  ngOnInit(): void {
    this.getEmpresas_Api();
    // this.getSedes_Api();
    // this.getPersonas_Api();
    this.getMoneda_Api();
    this.getTipoRequerimiento_Api();
    this.getBanco_Api();
  }
  closeModal(res: boolean) {
    this.closeModalEmmit.emit(res);
  }

  getPersonas_Api(): void {
    this._peronsaService.get().subscribe({
      next: (response: any) => {
        const response_data = response.data;
        if (response_data) {
          this.personas = response_data;
          setTimeout(() => {
            this.eventsSubject.next();
          }, 200);
        } else {
          console.error('No se encontraron datos');
        }
      },
      error: (error) => {
        console.log('Error al consumir el servicio', error);
      },
    });
  }

  getBanco_Api(): void {
    this._bancoService.get().subscribe({
      next: (response: any) => {
        const response_data = response.data;
        if (response_data) {
          this.bancos = response_data;
          setTimeout(() => {
            this.eventsSubject.next();
          }, 200);
        } else {
          console.error('No se encontraron datos');
        }
      },
      error: (error) => {
        console.log('Error al consumir el servicio', error);
      },
    });
  }

  getTipoRequerimiento_Api(): void {
    this._tipoRequerimientoService.get().subscribe({
      next: (response: any) => {
        const response_data = response.data;
        if (response_data) {
          this.tipos_concepto = response_data;
          setTimeout(() => {
            this.eventsSubject.next();
          }, 200);
        } else {
          console.error('No se encontraron datos');
        }
      },
      error: (error) => {
        console.log('Error al consumir el servicio', error);
      },
    });
  }

  getMoneda_Api(): void {
    this._monedaService.get().subscribe({
      next: (response: any) => {
        const response_data = response.data;
        if (response_data) {
          this.monedas = response_data;
          setTimeout(() => {
            this.eventsSubject.next();
          }, 200);
        } else {
          console.error('No se encontraron datos');
        }
      },
      error: (error) => {
        console.log('Error al consumir el servicio', error);
      },
    });
  }

  getEmpresas_Api(): void {
    this._empresaService.get().subscribe({
      next: (response: any) => {
        const response_data = response.data;
        if (response_data) {
          this.empresas = response_data;
          setTimeout(() => {
            this.eventsSubject.next();
          }, 200);
        } else {
          console.error('No se encontraron datos');
        }
      },
      error: (error) => {
        console.log('Error al consumir el servicio', error);
      },
    });
  }
  getSedes_Api(): void {
    this._sedeService.get().subscribe({
      next: (response: any) => {
        const response_data = response.data;
        if (response_data) {
          this.sedes = response_data;
          setTimeout(() => {
            this.eventsSubject.next();
          }, 200);
        } else {
          console.error('No se encontraron datos');
        }
      },
      error: (error) => {
        console.log('Error al consumir el servicio', error);
      },
    });
  }

  Get_SedesByEmpresaId_Api(object: DropdownChangeEvent): void {
    let sede_id = object.value;
    let request = {
      'empresa-id': sede_id,
    };
    this._sedeService.getByOther(request).subscribe({
      next: (response: any) => {
        const response_data = response.data;
        if (response_data) {
          this.sedes = response_data;
          setTimeout(() => {
            this.eventsSubject.next();
          }, 200);
        } else {
          console.error('No se encontraron datos');
        }
      },
      error: (error) => {
        console.log('Error al consumir el servicio', error);
      },
    });
  }

  Get_AreasBySedeId_Api(object: DropdownChangeEvent): void {
    let sede_id = object.value;
    let request = {
      'sede-id': sede_id,
    };
    this._areaService.getByOther(request).subscribe({
      next: (response: any) => {
        const response_data = response.data;
        if (response_data) {
          this.areas = response_data;
          setTimeout(() => {
            this.eventsSubject.next();
          }, 200);
        } else {
          console.error('No se encontraron datos');
        }
      },
      error: (error) => {
        console.log('Error al consumir el servicio', error);
      },
    });
  }

  Get_PersonasByAreaId_Api(object: DropdownChangeEvent): void {
    console.log('get_personas');
    let area_id = object.value;
    let request = {
      'area-id': area_id,
    };
    this._peronsaService.getByOther(request).subscribe({
      next: (response: any) => {
        const response_data = response.data;
        if (response_data) {
          this.personas = response_data;
          setTimeout(() => {
            this.eventsSubject.next();
          }, 200);
        } else {
          console.error('No se encontraron datos');
        }
      },
      error: (error) => {
        console.log('Error al consumir el servicio', error);
      },
    });
  }

  suscription: Subscription;
  loading: boolean = true;
  requerimiento: any = {};
  sede: any = {};
  persona: any = {};
  empresa: any = {};
  area: any = {};
  moneda: any = {};
  tipo_requerimiento: any = {};
  banco: any = {};
  /* Enviar datos del TS AL HTML (DB) */
  requerimieto_pago__db: any[][] = [];
  almacen_requerimieto_pago__db: any[] = []; // array de pobjetos

  //filas que agregar capturar los campos
  requerimientos: any[] = [];

  //agregar filas en el dom de la tabla
  agregarFila() {
    const nuevaFila = {
      tipo_concepto: null,
      concepto: null,
      tipo_pago: null,
      metodo_pago: null,
      tipo_comprobante: null,
      url_comprobante: null,
      simbolo_moneda: null,
      moneda: null,
      monto: null,
      nro_cuenta: null,
      banco_cuenta: null,
      titular_cuenta: null,
      detalles: null,
      fecha_pago: null,
    };

    this.requerimientos.push(nuevaFila);
  }
  //elimina una fila depende al index de la tabla en este caso elimina 1
  eliminarFila(index: number) {
    this.requerimientos.splice(index, 1);
  }

  registerRequerimientoPago(): void {
    // Preparar la solicitud
    const request = {
      persona_id: this.persona.id_persona,
      empresa_id: this.empresa.id_empresa,
      area_id: this.area.id_area,
      sede_id: this.sede.id_sede,
      requerimientos: JSON.parse(JSON.stringify(this.requerimientos)), // Clonación profunda
    };

    // Depuración: Verificar el contenido del request
    console.log(request);
    // Validar los campos obligatorios
    if (
      !request.persona_id ||
      !request.empresa_id ||
      !request.area_id ||
      !request.sede_id ||
      !Array.isArray(request.requerimientos) ||
      request.requerimientos.length === 0 ||
      request.requerimientos.some(
        (req) =>
          !req ||
          !req.tipo_concepto ||
          !req.concepto ||
          !req.tipo_pago ||
          !req.metodo_pago ||
          
          !req.simbolo_moneda ||
          !req.moneda ||
          !req.monto ||
         
          !req.fecha_pago
      )

    ) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Advertencia',
        detail: 'Asegúrese de rellenar todos los campos correctamente',
      });
      return;
    }

    // Realizar la solicitud al servicio
    this._reque_pago_service.create(request).subscribe({
      next: (response: any) => {
        if (response.status === 'CREATED') {
          alert_success('Registrado de forma correcta', 5000);

          // Emitir el nuevo registro
          this.registroAgregado.emit(response.data);

          // Cerrar el modal y limpiar
          this.closeModalEmmit.emit(true);
          this.hideDialog(); // Llama a hideDialog para limpiar todo
        } else {
          // En caso de error en la respuesta del servicio
          this.messageService.add({
            severity: 'warn',
            summary: 'Advertencia',
            detail:
              'Ocurrió un error al registrar los datos. Inténtelo de nuevo.',
          });
        }
      },
      error: (err) => {
        console.error('Error al registrar el requerimiento de pago', err);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail:
            'Ocurrió un error al comunicarse con el servidor. Inténtelo de nuevo.',
        });
        this.disableButton = false; // Habilitar el botón en caso de error
      },
    });
  }

  hideDialog() {
    // Limpiar los campos de entrada
    this.persona = {};
    this.empresa = {};
    this.sede = {};
    this.area = {};
    this.moneda = {};
    this.tipo_requerimiento = {};
    this.banco = {};

    // Limpiar la lista de requerimientos
    this.requerimientos = [];

    // Emitir el evento para cerrar el modal
    this.Close_Modal_Event3.emit();
  }
  filterInput(event: KeyboardEvent) {
    const allowedKeys = /^[0-9-]+$/;
    if (!allowedKeys.test(event.key)) {
      event.preventDefault();
    }
  }
}
