import { Injectable } from '@angular/core';


import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';



import { CrudService } from '../util/crud.service';
import { behaidorInterface } from '../interfaces/behaidor-interface';
import { urlConstants } from 'src/app/constants/url-constants';
import { Caja_Response } from 'src/app/response/gestion-caja/caja-response';
import { Caja_Request } from 'src/app/request/gestion-caja/caja-request';


@Injectable({
	providedIn: 'root',
})
export class Caja_Service
	extends CrudService<Caja_Request, Caja_Response>
	implements behaidorInterface<Caja_Response[]>
{
	private dataSource = new BehaviorSubject<Caja_Response[]>([]);

	constructor(protected http: HttpClient) {
		super(http, urlConstants.caja);
	}

	setValueBehaidor(value: Caja_Response[]): void {
		this.dataSource.next(value);
	}
	getValueBehaidorSubject() {
		return this.dataSource.getValue();
	}
	clearBehaidorSubject(): void {
		this.dataSource.next([]);
	}
}
