<div class="row">
	<div class="col-lg-12">
		<p-card>
			<div class="row mb-5">
				<div class="col-lg-2">
					<p-floatLabel>
						<input pInputText [(ngModel)]="DetallesCaja_Response.codigo" [disabled]="true">
						<label>Código </label>
					</p-floatLabel>
				</div>
				<div class="col-lg-2">
					<p-floatLabel>
						<input pInputText [(ngModel)]="DetallesCaja_Response.nombre_empresa" [disabled]="true">
						<label>Empresa</label>
					</p-floatLabel>
				</div>
				<div class="col-lg-2">
					<p-floatLabel>
						<input pInputText [(ngModel)]="DetallesCaja_Response.nombre_sede" [disabled]="true">
						<label>Sede</label>
					</p-floatLabel>
				</div>
				<div class="col-lg-2">
					<p-floatLabel>
						<input pInputText [(ngModel)]="DetallesCaja_Response.nombre" [disabled]="true">
						<label>Nombre</label>
					</p-floatLabel>
				</div>
				<div class="col-lg-2">
					<p-floatLabel>
						<input pInputText [(ngModel)]="DetallesCaja_Response.nombre_corto" [disabled]="true">
						<label>Nombre corto</label>
					</p-floatLabel>
				</div>
				<div class="col-lg-2">
					<p-floatLabel>
						<input pInputText [(ngModel)]="DetallesCaja_Response.nombre_largo" [disabled]="true">
						<label>Nombre largo</label>
					</p-floatLabel>
				</div>
			</div>
			<div class="row mb-5">
				<div class="col-lg-2">
					<p-floatLabel>
						<input pInputText [(ngModel)]="DetallesCaja_Response.fecha_inicio" [disabled]="true">
						<label>Fecha inicio</label>
					</p-floatLabel>
				</div>
				<div class="col-lg-2">
					<p-floatLabel>
						<input pInputText [(ngModel)]="DetallesCaja_Response.fecha_fin" [disabled]="true">
						<label>Fecha fin</label>
					</p-floatLabel>
				</div>
				<div class="col-lg-2">
					<p-floatLabel>
						<input pInputText [(ngModel)]="DetallesCaja_Response.estado" [disabled]="true">
						<label>Estado</label>
					</p-floatLabel>
				</div>
				<div class="col-lg-4">
					<p-floatLabel>
						<input pInputText class="w-100" [(ngModel)]="DetallesCaja_Response.detalles" [disabled]="true">
						<label>Detalles</label>
					</p-floatLabel>
				</div>
				<div class="col-lg-2">
					<p-floatLabel>
						<input pInputText [(ngModel)]="DetallesCaja_Response.monto_actual" [disabled]="true">
						<label>Saldo disponible</label>
					</p-floatLabel>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12">
					<h4>Movimientos</h4>

					<button pButton pRipple label="Registrar Movimiento" severity="success" class="mr-5"
						(click)="Open_Modal_RegistrarMovimientoCaja()">
					</button>

					<button pButton pRipple severity="info" class="mr-5" label="Ver resumen"
						(click)="Open_Modal_VerResumenMovimientoCaja()">

					</button>

					<button pButton pRipple severity="danger" label="Cerrar" (click)="Close_Modal_RegistrarCaja()">

					</button>

					<p-table [value]="Movimientos" [tableStyle]="{ 'min-width': '50rem' }"
						styleClass="p-datatable-gridlines p-datatable-striped">
						<ng-template pTemplate="header">
							<tr>
								<th>Tipo</th>
								<th>Fecha</th>
								<th>Concepto</th>
								<th>Moneda</th>
								<th>Ingreso</th>
								<th>Egreso</th>
								<th>Saldo disponible</th>
								<th>Tipo Pago</th>
								<th>Método Pago</th>
								<th>Tipo Comprobante</th>
								<th>Comprobante</th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-movimiento>
							<tr>
								<td>{{ movimiento.tipo }}</td>
								<td>{{ movimiento.fecha }}</td>
								<td>{{ movimiento.concepto }}</td>
								<td>{{ movimiento.moneda }}</td>
								<td>{{ ( movimiento.tipo == 'INGRESO' ) ? movimiento.monto : '' }}</td>
								<td>{{ ( movimiento.tipo == 'EGRESO' ) ? movimiento.monto : '' }}</td>
								<td>{{ movimiento.monto_disponible }}</td>
								<td>{{ movimiento.tipo_pago }}</td>
								<td>{{ movimiento.metodo_pago }}</td>
								<td>{{ movimiento.tipo_comprobante }}</td>
								<td>{{ movimiento.comprobante }}</td>
							</tr>
						</ng-template>

					</p-table>
				</div>
			</div>
		</p-card>
	</div>
</div>

<!-- Child Componentes -->
<!-- Registrar moviemiento de caja -->
<p-dialog header="Registrar movimiento de Caja" [modal]="true" [(visible)]="Display_Modal_RegistrarMovimientoCaja"
	[draggable]="false" [resizable]="false" [closable]="false" styleClass="custom-dialog"
	[style]="{width: '1200px', height: '900px', maxWidth: '90vw', maxHeight: '80vh'}">
	<app-registrar-movimiento-caja (Close_Modal_RegistrarMovimientCaja_Event)="Close_Modal_RegistrarMovimientoCaja()">
	</app-registrar-movimiento-caja>
</p-dialog>


	<!-- Ver resumen de movimiento -->
	<p-dialog header="Resumen de movimientos" [draggable]="false" [resizable]="false" [style]="{width: '1500px', height: '1500px', maxWidth: '150vw', maxHeight: '90vh'}" [modal]="true" [(visible)]="Display_Modal_VerResumenMovimientoCaja">
		<app-resumen-movimiento-caja [RowData_Parent]="formData"></app-resumen-movimiento-caja>
	</p-dialog>


