<div class="card flex gap-2" id="carta_1">
	<div class="w-9 mb-5">
		<div class="px-gutter pt-6 pb-20 bg-slate-900 flex-none">
			<div class="flex items-center justify-between" vexContainer>
				<h2 class="headline text-neutral-100 m-0 flex items-center w-full max-w-xxxl mr-6">
					<fa-icon [icon]="faTableList" class="hidden sm:block ml-5 fa-2xl"></fa-icon>
					<span class="ml-4 block pt-0">Reporte Cuenta </span>
				</h2>
			</div>
		</div>
	</div>


	<div class="container-fluid">
		<p-button class="boton-12" pButton type="button" label="Registrar Cuenta" (click)="showDialog()"></p-button>

		<div class="card text-dark bg-light mb-3">
			<div class="card-body">
				<p-table #dt2 [value]="cuenta_Response" dataKey="id" [rows]="10" [rowsPerPageOptions]="[10, 25, 50]"
					[paginator]="true" [globalFilterFields]="[
				'estado',
				'titular_cuenta',
				'concepto',
				'status'
			  ]">
					<ng-template pTemplate="caption">
						<div class="flex">
							<p-iconField iconPosition="left" class="ml-auto">
								<p-inputIcon>
									<i class="pi pi-search"></i>
								</p-inputIcon>

								<input pInputText #textInput type="text"
									(input)="dt2.filterGlobal(textInput.value, 'contains')"
									placeholder="Global Search" />
							</p-iconField>
						</div>
					</ng-template>

					<ng-template pTemplate="header">
						<tr>
							<th *ngFor="let col of cols">{{ col.header }}</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-RowData>
						<tr>
							<td *ngFor="let col of cols">
								{{ RowData[col.field] }}
							</td>
							<!-- <td>
					<p-splitButton label="ESTADO"
					[model]="getOptionsEstado_Button(RowData)"></p-splitButton>
				  </td> -->

							<button pButton pRipple label="Detalles" (click)="showDialog2(RowData)"
								class="p-button-success"></button>
						</tr>
					</ng-template>
				</p-table>
			</div>
		</div>
	</div>

	<p-dialog header="Registro Cuenta " [draggable]="false" [resizable]="false" [(visible)]="display" [modal]="true"
		(onHide)="hideDialog()">
		<app-registrar-cuenta-banc (Close_Modal_Event2)="Close_Modal()"
			>
		</app-registrar-cuenta-banc>
	</p-dialog>

	<p-dialog header="Detalle Cuenta Bancaria" [draggable]="false" [resizable]="false" class="dialog-modal" [(visible)]="visible"
		[modal]="true" (onHide)="hideDialog2()">
		<app-detalle-cuenta-banc (Close_Modal_Event2)="Close_Modal2()" [data]="RowData"></app-detalle-cuenta-banc>
	</p-dialog>


</div>