import { Injectable } from '@angular/core';


import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';



import { CrudService } from '../util/crud.service';
import { behaidorInterface } from '../interfaces/behaidor-interface';
import { urlConstants } from 'src/app/constants/url-constants';


import { Empresa_Request } from 'src/app/request/generalRequest/empresa-request';
import { Empresa_Response } from 'src/app/response/general-response/empresa-response';


@Injectable({
	providedIn: 'root',
})
export class Empresa_Service
	extends CrudService<Empresa_Request, Empresa_Response>
	implements behaidorInterface<Empresa_Response[]>
{
	private dataSource = new BehaviorSubject<Empresa_Response[]>([]);

	constructor(protected http: HttpClient) {
		super(http, urlConstants.empresa);
	}

	setValueBehaidor(value: Empresa_Response[]): void {
		this.dataSource.next(value);
	}
	getValueBehaidorSubject() {
		return this.dataSource.getValue();
	}
	clearBehaidorSubject(): void {
		this.dataSource.next([]);
	}
}
