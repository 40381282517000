export enum AdminRoutes {
  Dashboard = 'dashboard',
  Login = 'login',

  Desembolso = 'desembolso',
  Caja = 'caja',
  Cuenta = 'cuenta',
  Test = 'test'
}

export enum ElementRoutes {

  Registrar_requerimiento_pago = 'registrar-req-pago',
  Reporte_requerimiento_pago = 'reporte-req-pago',
  Reporte_liquidacion_gastos = 'reporte-liquidacion-gastos',
  Registrar_liquidacion_gastos = 'registro-liquidacion-gastos',
  Reporte_desembolso = 'reporte-desembolso',
  ////////////////////////////////////////
  Registrar_caja = 'registrar-caja',
  Reporte_caja = 'reporte-caja',
 ////
 Registrar_cuenta = 'registrar-cuenta',
 Reporte_cuenta = 'reporte-cuenta',
 Movimiento_cuenta = 'movimiento-cuenta',
 Upload_File = 'upload-file'

 ///*//////
}

export enum SettingRoutes {
  Profile = 'profile',
  Users = 'users',
}
