import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private dataSubject = new Subject<void>();

  dataUpdated$ = this.dataSubject.asObservable();

  notifyDataChange(): void {
    this.dataSubject.next();
  }
}
