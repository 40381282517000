<div class="modal-header">
    <h4 id="h41" class="modal-title pull-left">{{ "Registrar Caja" }}</h4>
</div>

<p-card>
    <form [formGroup]="form">
        <div class="row">
            <div class="col-lg-3">
                <span class="p-float-label">
                    <p-dropdown [showClear]="true" placeholder="Seleccione Empresa" [options]="Empresas" 
                        optionValue="id_empresa" optionLabel="nombre" formControlName="empresa_id"
                        (onChange)="Get_Sedes_By_EmpresaId_Api($event)"></p-dropdown>
                    <label>Empresa</label>
                    <small *ngIf="form.get('empresa_id')?.invalid && (form.get('empresa_id')?.dirty || form.get('empresa_id')?.touched)" class="p-error">
                        {{ getErrorMessage('empresa_id') }}
                      </small>
                </span>
            </div>
            
            <div class="col-lg-3">
                <span class="p-float-label">
                    <p-dropdown [showClear]="true" placeholder="Selecciona Categoría" [options]="Sedes" 
                        optionValue="id_sede" optionLabel="nombre" formControlName="sede_id"></p-dropdown>
                    <label>Sede</label>
                    <small *ngIf="form.get('sede_id')?.invalid && (form.get('sede_id')?.dirty || form.get('sede_id')?.touched)" class="p-error">
                        {{ getErrorMessage('sede_id') }}
                      </small>
                </span>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-lg-3">
                <span class="p-float-label">
                    <input pInputText class="form-control" formControlName="nombre">
                    <label>Nombre</label>
                    <small *ngIf="form.get('nombre')?.invalid && (form.get('nombre')?.dirty || form.get('nombre')?.touched)" class="p-error">
                        {{ getErrorMessage('nombre') }}
                      </small>
                </span>
            </div>
            <div class="col-lg-3">
                <span class="p-float-label">
                    <input pInputText class="form-control" formControlName="nombre_corto">
                    <label>Nombre Corto</label>
                    <small *ngIf="form.get('nombre_corto')?.invalid && (form.get('nombre_corto')?.dirty || form.get('nombre_corto')?.touched)" class="p-error">
                        {{ getErrorMessage('nombre_corto') }}
                      </small>
                </span>
            </div>
            <div class="col-lg-3">
                <span class="p-float-label">
                    <input pInputText class="form-control" formControlName="nombre_largo">
                    <label>Nombre Largo</label>
                    <small *ngIf="form.get('nombre_largo')?.invalid && (form.get('nombre_largo')?.dirty || form.get('nombre_largo')?.touched)" class="p-error">
                        {{ getErrorMessage('nombre_largo') }}
                      </small>
                </span>
            </div>
            <div class="col-lg-3">
                <span class="p-float-label">
                    <input pInputText class="form-control" formControlName="detalles">
                    <label>Detalles</label>
                    <small *ngIf="form.get('detalles')?.invalid && (form.get('detalles')?.dirty || form.get('detalles')?.touched)" class="p-error">
                        {{ getErrorMessage('detalles') }}
                      </small>
                </span>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-lg-3">
                <span class="p-float-label">
                    <p-calendar 
                    [showTime]="false" 
                    dateFormat="yy/mm/dd" 
                    formControlName="fecha_inicio" 
                  >
                </p-calendar>
                    <label>Fecha Inicio</label>
                    <small *ngIf="form.get('fecha_inicio')?.invalid && (form.get('fecha_inicio')?.dirty || form.get('fecha_inicio')?.touched)" class="p-error">
                        {{ getErrorMessage('fecha_inicio') }}
                      </small>
                </span>
            </div>
            <div class="col-lg-3">
                <span class="p-float-label">
                    <p-calendar [showTime]="false" dateFormat="yy/mm/dd" formControlName="fecha_fin"  ></p-calendar>
                    <label>Fecha Fin</label>
                    <small *ngIf="form.get('fecha_fin')?.invalid && (form.get('fecha_fin')?.dirty || form.get('fecha_fin')?.touched)" class="p-error">
                        {{ getErrorMessage('fecha_fin') }}
                      </small>
                </span>
            </div>
            <div class="col-lg-2">
                <span class="p-float-label">
                    <p-dropdown [showClear]="true" placeholder="Moneda" [options]="Monedas" optionValue="nombre" 
                        optionLabel="nombre" formControlName="moneda"></p-dropdown>
                    <label>Moneda</label>
                    <small *ngIf="form.get('moneda')?.invalid && (form.get('moneda')?.dirty || form.get('moneda')?.touched)" class="p-error">
                        {{ getErrorMessage('moneda') }}
                      </small>
                </span>
            </div>
            <div class="col-lg-2">
                <span class="p-float-label">
                    <p-dropdown [showClear]="true" placeholder="S/Moneda" [options]="Monedas" optionValue="simbolo" 
                        optionLabel="simbolo" formControlName="simbolo_moneda"></p-dropdown>
                    <label>Símbolo</label>
                    <small *ngIf="form.get('simbolo_moneda')?.invalid && (form.get('simbolo_moneda')?.dirty || form.get('simbolo_moneda')?.touched)" class="p-error">
                        {{ getErrorMessage('simbolo_moneda') }}
                      </small>
                </span>
            </div>
            <div class="col-lg-2">
                <span class="p-float-label">
                    <input pInputText class="form-control" 
                        title="Debe ser un número con al menos dos decimales" formControlName="monto_inicial">
                    <label>Monto Inicial</label>
                    <small *ngIf="form.get('monto_inicial')?.invalid && (form.get('monto_inicial')?.dirty || form.get('monto_inicial')?.touched)" class="p-error">
                        {{ getErrorMessage('monto_inicial') }}
                      </small>
                </span>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-lg-12 text-end">
                <div class="register-botton">
                    <button pButton type="button" class="p-button-success ml-1" icon="pi pi-save" label="Registrar"
                        (click)="Register_Caja_APi()"></button>
                    <p-button label="Cancelar" (click)="hideDialog()" [raised]="true"
                        severity="danger"></p-button>
                </div>
            </div>
        </div>
    </form>
</p-card>
