export class MovimientoCaja_Request {
    id_movimiento_caja: number | null;
    caja_id: number | null;
    tipo: string | null;
    tipo_concepto: string | null;
    concepto: string | null;
    fecha: string | null;
    simbolo_moneda: string | null;
    moneda: string | null;
    monto: number | null;
    monto_disponible: number | null;
    tipo_pago: string | null;
    metodo_pago: string | null;
    tipo_comprobante: string | null;
    comprobante: string | null;
    detalles: string | null;
    descripcion: string | null;

    constructor() {
        this.id_movimiento_caja = 0;
        this.caja_id = 0;
        this.tipo = '';
        this.tipo_concepto = '';
        this.concepto = '';
        this.fecha = '';
        this.simbolo_moneda = '';
        this.moneda = '';
        this.monto = 0;
        this.monto_disponible = 0;
        this.tipo_pago = '';
        this.metodo_pago = '';
        this.tipo_comprobante = '';
        this.comprobante = '';
        this.detalles = '';
        this.descripcion = '';
    }
}
