export class Caja_Response {
    id_caja: number | null;
    empresa_id: number | null;
    sede_id?: number | null;
    nombre: string ;
    nombre_corto: string;
    nombre_largo: string;
    fecha_inicio: string;
    fecha_fin: string;
    moneda: string;
    simbolo_moneda: string;
    monto_inicial: string;
    detalles: string;
  
    constructor() {
      this.id_caja = 0;
      this.empresa_id = 0;
      this.sede_id = 0;
      this.nombre = '';
      this.nombre_corto = '';
      this.nombre_largo = '';
      this.fecha_inicio = '';
      this.fecha_fin = '';
      this.moneda = '';
      this.simbolo_moneda = '';
      this.monto_inicial = '';
      this.detalles = '';
    }
  }
  