<div class="row">
    <div class="col-lg-12">
        <div class="row">
            <div class="col-lg-6">
                <label>Periodo: </label>
            </div>
            <div class="col-lg-6 text-end font-bold">
                <label>Saldo inicial: {{ ResumenMovimiento_Caja.monto_inicial }} </label>
            </div>
        </div>
        <div class="row" styleClass="p-datatable-gridlines p-datatable-striped">
            <p-table #dt2>
                <ng-template pTemplate="header">
                    <tr>
                        <th>Fecha</th>
                        <th>Tipo Pago</th>
                        <th>Tipo Concepto</th>
                        <th>Concepto</th>
                        <th>Tipo Comprobante</th>
                        <th>Comprobante</th>
                        <th>Importe</th>
                        <!-- <th *ngFor="let col of cols">{{ col.header }}</th> -->
                    </tr>
                    <tr>
                        <th colspan="7">INGRESOS</th>
                    </tr>
                    <tr *ngFor="let Ingreso of Ingresos">
                        <!-- <td> tipo </td> -->
                        <td>
                            {{ Ingreso.fecha }}
                        </td>
                        <td>
                            {{ Ingreso.tipo_pago }}
                        </td>
                        <td>
                            {{ Ingreso.tipo_concepto }}
                        </td>
                        <td>
                            {{ Ingreso.concepto }}
                        </td>
                        <td>
                            {{ Ingreso.tipo_comprobante }}
                        </td>
                        <td>
                            {{ Ingreso.comprobante }}
                        </td>
                        <td>
                            {{ Ingreso.monto }}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="7"></td>
                    </tr>
                    <tr>
                        <th colspan="6">TOTAL INGRESOS</th>
                        <th> {{ ResumenMovimiento_Caja.total_ingresos }} </th>
                    </tr>
                </ng-template>
            </p-table>
            <p-table>
                <ng-template pTemplate="header">
                    <tr>
                        <th>Fecha</th>
                        <th>Tipo Pago</th>
                        <th>Tipo Concepto</th>
                        <th>Concepto</th>
                        <th>Tipo Comprobante</th>
                        <th>Comprobante</th>
                        <th>Importe</th>
                    </tr>
                    <tr>
                        <th colspan="7">EGRESOS</th>
                    </tr>
                    <tr *ngFor="let Egreso of Egresos">
                        <!-- <td> tipo </td> -->
                        <td>
                            {{ Egreso.fecha }}
                        </td>
                        <td>
                            {{ Egreso.tipo_pago }}
                        </td>
                        <td>
                            {{ Egreso.tipo_concepto }}
                        </td>
                        <td>
                            {{ Egreso.concepto }}
                        </td>
                        <td>
                            {{ Egreso.tipo_comprobante }}
                        </td>
                        <td>
                            {{ Egreso.comprobante }}
                        </td>
                        <td>
                            {{ Egreso.monto }}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="7"></td>
                    </tr>
                    <tr>
                        <th colspan="6">TOTAL EGRESOS</th>
                        <th> {{ ResumenMovimiento_Caja.total_egresos }} </th>
                    </tr>
                </ng-template>
            </p-table>
            <p-table>
                <ng-template pTemplate="header">
                    <tr>
                        <td width="10%"></td>
                        <td width="10%"></td>
                        <td width="10%"></td>
                        <td width="10%"></td>
                        <td width="10%"></td>
                        <td width="10%"></td>
                        <td width="10%"></td>
                    </tr>
                    <tr>
                        <th colspan="6">SALDO CAJA</th>
                        <th> {{ ResumenMovimiento_Caja.monto_actual }} </th>
                    </tr>
                    <tr>
                        <td colspan="7">Obervaciones</td>
                    </tr>
                    <tr>
                        <td colspan="7"></td>
                    </tr>
                    <tr>
                        <td colspan="7"></td>
                    </tr>
                </ng-template>
                
            </p-table>
        </div>

    </div>
</div>