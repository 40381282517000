export class MovimientoCuenta_Request {
  cuenta_id: number | null;
  periodo_id: number | null;

  tipo: string | null;
  concepto: string | null;
  detalles : string | null;
  moneda: string | null;
  simbolo_moneda: string | null;
  monto: number | null;
  tipo_pago: string | null;
  metodo_pago: string | null;
  tipo_comprobante: string | null;
  comprobante: string | null;

  constructor() {
    this.cuenta_id = 0;
    this.periodo_id = 0;

    this.tipo = '';
    this.concepto = '';
    this.detalles = '';
    this.moneda = '';
    this.simbolo_moneda = '';

    this.monto = 0;

    this.tipo_pago = '';
    this.metodo_pago = '';
    this.tipo_comprobante = '';
    this.comprobante = '';
  }
}
