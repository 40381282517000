<!-- Información de la persona solicitada -->
<div class="p-field">
  <label>Responsable:</label>
  <input readonly pInputText [(ngModel)]="formData.persona__solicita" name="persona_solicita" class="full-width readonly-input"/>
</div>

<!-- Tabla para mostrar detalles de liquidación de gasto -->
<div *ngIf="detallesGastos.length > 0">
  <table class="full-width">
    <thead>
      <tr>
        <th>Fecha</th>
        <th>Documento</th>
        <th>Nº</th>
        <th>Concepto</th>
        <th>Monto</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let detalle of detallesGastos">
        <td>{{ detalle.fecha_comprobante }}</td>
        <td>{{ detalle.tipo_comprobante }}</td>
        <td>{{ detalle.nro_comprobante }}</td>
        <td>{{ detalle.concepto }}</td>
      
        <td>{{ detalle.monto }}</td>
      </tr>
    </tbody>
  </table>
</div>

<!-- Sección de Totales -->
<!-- Sección de Totales -->
<div class="total-section">
  <h3>Total:</h3>
  <div class="total-field">
    <label for="monto_desembolso">Monto Desembolso:</label>
    <input id="monto_desembolso" readonly pInputText [(ngModel)]="formData.monto_desembolso" name="monto_desembolso" class="full-width readonly-input1"/>
  </div>
  <div class="total-field">
    <label for="saldo_desembolso">Saldo Desembolso:</label>
    <input id="saldo_desembolso" readonly pInputText [(ngModel)]="formData.saldo_desembolso" name="saldo_desembolso" class="full-width readonly-input"/>
  </div>
  <div class="total-field">
    <label for="otro_campo">Total Gastado:</label>
    <input id="otro_campo" readonly pInputText [(ngModel)]="formData.otro_campo" name="otro_campo" class="full-width readonly-input2"/>
  </div>
</div>

