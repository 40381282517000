export const tipoControl= [
    {
        id: "button",
        text: "button"
    },
    {
        id: "text-control",
        text: "text-control"
    }
]

export const restriccionTipos = [
    {
        id: "Disabled",
        text: "Disabled"
    },
    {
        id: "hidden",
        text: "hidden"
    }
]

export const paggingConst = {
    FILAS_PAGINADO: 5
}

export const typeFilterConst = {
    TextControl: 1,
    ComboBox: 2,
    AutoComplete: 3,
    datePicker: 4
}


/*Botenes de editar-eiminar-etc */
export const accionConst = {
    add: 1,
    edit: 2,
    config: 3,
    delete: 4,
    edit_enlace : 5,
    detalles : 6,
    aprobar : 7
}



