import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedDataService {
  private ventaDataSubject = new BehaviorSubject<any>(null);
  ventaData$ = this.ventaDataSubject.asObservable();

  setVentaData(data: any) {
    this.ventaDataSubject.next(data);
  }
}
