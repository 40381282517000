import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { MessageService } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { Subject } from 'rxjs';
import { alert_success, alert_warning } from 'src/app/functions/alert-function';
import { MovimientoCuenta_Request } from 'src/app/request/gestion-cuenta/movimiento-cuenta-request';
import { Moneda_Service } from 'src/app/services/general-services/moneda.service';
import { PeriodoService } from 'src/app/services/general-services/periodo.service';
import { Caja_Service } from 'src/app/services/gestion-caja/gestion_caja.service';
import { Cuenta_Service } from 'src/app/services/gestion-cuenta/gestion-cuenta.service';
import { MovimientoCuenta_Service } from 'src/app/services/gestion-cuenta/movimiento_cuenta.service';
import { PeriodoCuentaService } from 'src/app/services/gestion-cuenta/periodo-cuenta.service';

@Component({
	selector: 'app-registrar-movimiento-cuenta',
	standalone: true,
	imports: [
		FormsModule,

		FloatLabelModule,
		ReactiveFormsModule,
		CommonModule,
		DropdownModule,
		CalendarModule,
		InputTextModule,
		CardModule,
		ToastModule,
	],
	templateUrl: './registrar-movimiento-cuenta.component.html',
	styleUrl: './registrar-movimiento-cuenta.component.css',
	providers: [MessageService],
})
export class RegistrarMovimientoCuentaComponent {
	@Output() Close_Modal_Event3 = new EventEmitter<void>();
	@Output() registroAgregado = new EventEmitter<any>();
	eventsSubject: Subject<void> = new Subject<void>();
	form: FormGroup;
	errors: any = {};

	MovimientoCuenta_Request: MovimientoCuenta_Request =
		new MovimientoCuenta_Request();

	@Input() Data_Parent: any;

	/* Data Forms Inputs */
	cuentas: any[] = [];
	id_cuenta: any;
	periodos_cuenta: any[] = [];
	Monedas: any[] = [];

	Tipos: any[] = [
		{
			id_tipo: 1,
			nombre: 'INGRESO',
		},
		{
			id_tipo: 2,
			nombre: 'EGRESO',
		},
	];
	TiposPago: any[] = [
		{
			id_tipo_pago: 1,
			nombre: 'FÍSICO',
		},
		{
			id_tipo_pago: 2,
			nombre: 'DIGITAL',
		},
	];
	MetodosPago: any[] = [
		{
			id_metodo_pago: 1,
			nombre: 'EFECTIVO',
		},
		{
			id_metodo_pago: 2,
			nombre: 'YAPE',
		},
		{
			id_metodo_pago: 3,
			nombre: 'TRANSFERENCIA',
		},
	];
	TiposComprobante: any[] = [
		{
			id_tipo_comprobante: 1,
			nombre: 'VOUCHER',
		},
	];
	constructor(
		private _Caja_Service: Caja_Service,
		private _movimientoCuenta: MovimientoCuenta_Service,
		private _Moneda_Service: Moneda_Service,
		private fb: FormBuilder,
		private _Cuenta_service: Cuenta_Service,
		private messageService: MessageService,
		private _Periodo_service: PeriodoService,
		private _PeriodoCuenta_service: PeriodoCuentaService
	) {
		this.form = this.fb.group({
			id_cuenta: [null, Validators.required],
			tipo: [null, Validators.required],
			concepto: [null, Validators.required],
			id_periodo_cuenta: ['', Validators.required],
			moneda: ['', Validators.required],
			simbolo_moneda: ['', Validators.required],
			monto: ['', Validators.required],
			tipo_pago: ['', Validators.required],
			metodo_pago: ['', Validators.required],
			tipo_comprobante: ['', Validators.required],
			comprobante: ['', Validators.required],
			detalles: ['', Validators.required],
		});

		// Limpia los errores al cambiar el valor en los campos del formulario
		this.limpiar_validacion_escribir();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['Data_Parent'] && changes['Data_Parent'].currentValue) {
			// this.form = { ...changes['Data_Parent'].currentValue };

			this.form.patchValue({ ...changes['Data_Parent'].currentValue });
			this.id_cuenta = changes['Data_Parent'].currentValue.id_cuenta;
			if (this.id_cuenta) {
				this.Get_PeriodosCuenta_By_CuentaId_Api();
			}
		}
	}

	ngOnInit(): void {
		this.Get_Cuenta_Api();
		this.Get_Monedas_Api();
	}

	limpiar_validacion_escribir(): void {
		Object.keys(this.form.controls).forEach((key) => {
			this.form.get(key)?.valueChanges.subscribe(() => {
				this.errors[key] = null; // Limpia el error cuando el usuario empieza a escribir
			});
		});
	}
	Get_Cuenta_Api(): void {
		this._Cuenta_service.get().subscribe({
			next: (response: any) => {
				if (response.status == 'OK') {
					this.cuentas = response.data;

					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontró ningún dato');
				}
			},
			error: (error) => {
				console.log('Error al llamar el servicio', error);
			},
		});
	}
	Get_PeriodosCuenta_By_CuentaId_Api(): void {
		const request = {
			'cuenta': this.id_cuenta
		}
		this._PeriodoCuenta_service.getByOther(request).subscribe({
			next: (response: any) => {
				if (response.status == 'OK') {
					this.periodos_cuenta = response.data;

					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontró ningún dato');
				}
			},
			error: (error) => {
				console.log('Error al llamar el servicio', error);
			},
		});
	}
	Get_Monedas_Api(): void {
		this._Moneda_Service.get().subscribe({
			next: (response: any) => {
				if (response.status == 'OK') {
					this.Monedas = response.data;
					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontró ningún dato');
				}
			},
			error: (error) => {
				console.log('Error al llamar el servicio', error);
			},
		});
	}

	Registrar_MovimientoCuenta(): void {
		if (this.form.invalid) {
			this.form.markAllAsTouched(); // Marca todos los campos como tocados para mostrar errores
			return;
		}

		this._movimientoCuenta.create(this.form.value).subscribe({
			next: (response: any) => {
				if (response.status === 'OK') {
					this.messageService.add({
						severity: 'success',
						summary: 'Éxito',
						detail: 'El registro se realizó correctamente',
						life: 5000,
					});

					this.registroAgregado.emit();

					this.form.reset();

					setTimeout(() => {
						this.Close_Modal_Event3.emit();
					}, 3000);
				} else {
					this.messageService.add({
						severity: 'warn',
						summary: 'Advertencia',
						detail: 'No hay suficiente saldo',
					});
				}
			},
			error: (error) => {
				console.log('Error al llamar el servicio', error);

				this.messageService.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Ocurrió un error al procesar la solicitud',
				});
			},
		});
	}

	hideDialog() {
		this.form.reset();
		this.Close_Modal_Event3.emit();
	}

	getErrorMessage(controlName: string): string {
		const control = this.form.get(controlName);
		if (control!.hasError('required')) {
			return 'Este campo es obligatorio';
		}
		if (control!.hasError('pattern')) {
			return 'Formato no válido';
		}
		return '';
	}
}
