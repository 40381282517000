export class Liqu_gastos_Response {
  id_desembolso: number | null;
  detalles? : string ;

  detalles_liquidacion_gasto : any;

  constructor () {
      this.id_desembolso = 0;
      this.detalles = '';

      this.detalles_liquidacion_gasto = '';
  }
}
