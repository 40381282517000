import { Component, Input } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTableList } from '@fortawesome/free-solid-svg-icons';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TableModule } from 'primeng/table';
import { Subject } from 'rxjs';

import { tableCol } from 'src/app/common/table-cols';

import { SharedDataService } from 'src/app/services/util/SharedData.service';
import { TablePaggingComponent } from '../../util/table-pagging/table-pagging.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { PaginatorModule } from 'primeng/paginator';

import { Requerimiento_Pago_Service } from 'src/app/services/gestion-desembolso/registro-req-pago.service';
import { Req_Pago_Response } from 'src/app/response/gestion-desembolso/registrar-requerimiento-pago-response';
import { RegistrarReqPagoComponent } from '../registrar-req-pago/registrar-req-pago.component';
import Swal from 'sweetalert2';
import { ProgramarDesembolsoComponent } from '../programar-desembolso/programar-desembolso.component';
import { TooltipModule } from 'primeng/tooltip';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { CommunicationService } from 'src/app/services/general-services/comunicacion.service';
import { MenuItem } from 'primeng/api';
import { SplitButtonModule } from 'primeng/splitbutton';
import { UpdateEstadoRequerimientoPagoComponent } from './update-estado-requerimiento-pago/update-estado-requerimiento-pago.component';

interface City {
  name: string;
  code: string;
}

@Component({
  selector: 'app-reporte-req-pago',
  standalone: true,
  imports: [
    FontAwesomeModule,
    TableModule,
    TablePaggingComponent,
    RegistrarReqPagoComponent,
    ProgramarDesembolsoComponent,
    UpdateEstadoRequerimientoPagoComponent,
    CommonModule,
    TooltipModule,
    ButtonModule,
    DialogModule,
    FormsModule,
    PaginatorModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    SplitButtonModule,
  ],
  templateUrl: './reporte-req-pago.component.html',
  styleUrl: './reporte-req-pago.component.css',
  providers: [BsModalService, SharedDataService],
})
export class ReporteReqPagoComponent {
  display: boolean = false; // Controla la visibilidad del diálogo
  visible: boolean = false;
  visible2: boolean = false;
  displayModal: boolean = false;
  ////////////////////////////////
  @Input() req_Pago_Response: any[] = [];
  RowData: any;
  faTableList = faTableList;
  loading: boolean = false;
  eventsSubject: Subject<void> = new Subject<void>();
  valuesTable: Req_Pago_Response[] = [];
  cols: tableCol[] = [
    // { field: 'id_requerimiento_pago', header: 'ID' },
    { field: 'nombre_empresa', header: 'Empresa' },
    { field: 'nombre_sede', header: 'Sede' },
    { field: 'nombre_area', header: 'Area' },
    { field: 'nombres_persona', header: 'Solicitante' },
    { field: 'tipo_concepto', header: 'Tipo Concepto' },
    { field: 'concepto', header: 'Concepto' },
    { field: 'moneda', header: 'Moneda' },
    { field: 'monto', header: 'Monto' },
    // { field: 'tipo', header: 'Tipo' },
    { field: 'nro_cuenta', header: 'Nº Cuenta' },
    { field: 'banco_cuenta', header: 'Banco' },
    { field: 'titular_cuenta', header: 'Titular' },
    //{ field: 'documento_justificacion', header: 'Doc Justificación' },
    { field: 'detalles_estado', header: 'Detalles Estado' },
    { field: 'estado', header: 'Estado' },
    { field: 'Accion', header: 'Accion' },
  ];

  /* Modal independiente */ /* Frank Fabian */
  Show_Modal: string | null = null;
  Selected_RowData: any = null;

  isAprobarModalVisible(action: string): boolean {
    return this.Show_Modal === 'APROBADO';
  }

  constructor(
    private _Requerimiento_Pago_Service: Requerimiento_Pago_Service,
    private communicationService: CommunicationService
  ) { }

  ngOnInit(): void {
    this.GetRequqerimientoPago_Api();
    this.communicationService.data$.subscribe((data) => {
      this.req_Pago_Response = data;
    });
  }

  optionsEstadoButtonMap: Map<any, MenuItem[]> = new Map();
  getOptionsEstado_Button(RowData_R: any): MenuItem[] {
    if (!this.optionsEstadoButtonMap.has(RowData_R)) {
      const options = [
        {
          label: 'APROBAR',
          command: () => this.Open_Modal_f(RowData_R, 'APROBADO'),
        },
        {
          label: 'PROGRAMAR',
          command: () => this.Open_Modal_f(RowData_R, 'PROGRAMADO'),
        },
      
        {
          label: 'DESEMBOLSAR',
          command: () => this.Open_Modal_f(RowData_R, 'DESEMBOLSADO'),
        },
     
      ];
      this.optionsEstadoButtonMap.set(RowData_R, options);
    }
    return this.optionsEstadoButtonMap.get(RowData_R) || [];
  }

 


  ActualizarEstadoAgregado(nuevoRegistro: any): void {
    if (nuevoRegistro && nuevoRegistro.id_requerimiento_pago) {
      const index = this.req_Pago_Response.findIndex(
        (item) => item.id_requerimiento_pago === nuevoRegistro.id_requerimiento_pago
      );

      if (index > -1) {
        this.req_Pago_Response[index] = {
          ...this.req_Pago_Response[index],
          estado: nuevoRegistro.estado,
          detalles_estado: nuevoRegistro.detalles_estado
        };
      } else {
        this.req_Pago_Response.push(nuevoRegistro);
      }

      this.hideDialog();
    } else {
      console.error('El nuevo registro es undefined o no tiene un id válido');
    }
  }






  ActualizarRegistroAgregado(nuevoRegistro: any): void {
    if (nuevoRegistro && nuevoRegistro.id_requerimiento_pago) {
      const index = this.req_Pago_Response.findIndex(
        (item) =>
          item.id_requerimiento_pago === nuevoRegistro.id_requerimiento_pago
      );
      if (index > -1) {
        // Actualiza el registro existente
        this.req_Pago_Response[index] = nuevoRegistro;
      } else {
        // Agrega nuevo registro al inicio de la lista
        this.req_Pago_Response.unshift(nuevoRegistro);
      }

      // Llama a la API para obtener la lista completa actualizada
      this.GetRequqerimientoPago_Api();
    } else {
      console.error('El nuevo registro es undefined o no tiene un id válido');
    }
    this.hideDialog();
  }





  GetRequqerimientoPago_Api() {
    this.loading = false;
    this._Requerimiento_Pago_Service.get().subscribe({
      next: (response: any) => {
        const response_data = response.data;
        console.log(response_data);
        this.loading = true;
        if (response_data) {
          this.req_Pago_Response = response_data;
          setTimeout(() => {
            this.eventsSubject.next();
          }, 200);
        } else {
          console.error('No se encontró ningún dato');
        }
      },
      error: (error) => {
        this.loading = true;
        console.log('Error al llamar el servicio', error);
      },
    });
  }

  ///MODALS///
  showDialog() {
    this.display = true;
  }

  hideDialog() {
    this.display = false;
  }

  showDialog2(RowData_R: any) {
    this.RowData = RowData_R;
    this.visible = true;
  }

  hideDialog2() {
    this.visible = false;
  }

  showDialog3(RowData_R: any) {
    this.RowData = RowData_R; // Guarda los datos en la propiedad
    this.visible2 = true; // Muestra el diálogo
  }

  hideDialog3() {
    this.visible2 = false;
  }

  Close_Modal() {
    this.visible2 = false;
  }

  Close_Modal2() {
    this.visible = false;
  }
  Close_Modal3() {
    this.display = false;
  }

  Open_Modal_f(RowData_R: any, Action: string): void {
    this.RowData = { ...RowData_R };
    this.Show_Modal = Action;
    this.RowData.estado = Action;
    this.displayModal = true;
  }

  Close_Modal_f() {
    this.displayModal = false;
  }

  getEstadoClass(estado: string): string {
    // Normaliza el valor del estado
    const estadoNormalizado = estado ? estado.trim().toLowerCase() : '';

    switch (estadoNormalizado) {
      case 'aprobado':
        return 'estado-aprobado';
      case 'pendiente':
        return 'estado-pendiente';
      case 'rechazado':
        return 'estado-rechazado';
      case 'desembolsado':
        return 'estado-desembolsado';
      case 'liquidado':
        return 'estado-liquidado';
      case 'observado':
        return 'estado-observado';
      case 'programado':
        return 'estado-programado';
      default:
        return '';
    }
  }
}
