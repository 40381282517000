import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, Validators } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTableList } from '@fortawesome/free-solid-svg-icons';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { Subject, Subscription } from 'rxjs';
import { alert_error, alert_success, alert_warning } from 'src/app/functions/alert-function';
import { Moneda_Service } from 'src/app/services/general-services/moneda.service';
import { Desembolso_Service } from 'src/app/services/gestion-desembolso/desembolso.service';
import { Liquidacion_Gastos_Service } from 'src/app/services/gestion-desembolso/liquidacion-gastos.service';
import { ReporteDesembolsoService } from 'src/app/services/gestion-desembolso/reporte-desembolso.service';


interface City {
    name: number;
    code: string;
}
interface Moneda {
    name: string,
    code: string
}

@Component({
    selector: 'app-registrar-liquidacion-gastos',
    standalone: true,
    imports: [
        CardModule,
        InputNumberModule,
        TableModule,
        DropdownModule,
        CalendarModule,
        ButtonModule,
        FormsModule,
        InputTextModule,
        TooltipModule,
        DividerModule,
        CommonModule,
        SelectButtonModule,
        FontAwesomeModule,
        DialogModule,
        FormsModule,
    ],
    templateUrl: './registrar-liquidacion-gastos.component.html',
    styleUrl: './registrar-liquidacion-gastos.component.css'
})
export class RegistrarLiquidacionGastosComponent {
    faTableList = faTableList;
    @Input() title: string = '';
    @Output() Close_Modal_Event3 = new EventEmitter<boolean>();

    @Output() registrosAgregado = new EventEmitter<any>();
    disableButton: boolean = false;
    RegistroLiquidacion_Form: FormGroup;
    Button_Disabled: boolean = false;
    eventsSubject: Subject<void> = new Subject<void>();
    formVisible = false;
    selectedOption: any;


    /*select unidad de medida */
    //unidad_medida: Unidadmedida[] | undefined;

    //Select_Unidad_Medida: Unidadmedida | undefined;
    prodicto2: any = {};
    cities: City[] | undefined;
    selectedCity: City | undefined;
    desembolsos: any[] = [];
    desembolso: any = {};

    selectedMoney: Moneda | undefined;
    Liqu_gastos_Response!: any[];

    constructor(
        private fb: FormBuilder,
        private _liquidacion_gastos_service: Liquidacion_Gastos_Service,
        private _ReporteDesembolso_Service: ReporteDesembolsoService,
        private _Desembolso_Service: Desembolso_Service,
        private _monedaService: Moneda_Service,
    ) {
        this.RegistroLiquidacion_Form = this.fb.group({
            //  almacen_id: [null, [Validators.required]],
            //  proveedor_id: [null, [Validators.required]],
            //  productos: [],
        });
    }

    get f() {
        return this.RegistroLiquidacion_Form.controls;
    }
    
    @ViewChild('contenedorInputs', { static: true }) contenedorInputs: ElementRef;

    ngOnInit(): void {

        this.Get_Desembolsos_Api();
        this.getMoneda_Api();


    }


    Tipo_comprobante: any[] = [
        { label: 'BOLETA', value: 'boleta' },
        { label: 'RUC', value: 'ruc' }
    ];

    Get_Desembolsos_Api() {
        this.loading = false;
        this._ReporteDesembolso_Service.get().subscribe({
            next: (response: any) => {
                const response_data = response.data;
                console.log(response_data);
                this.loading = true;
                if (response_data) {
                    this.desembolsos = response_data;
                    this.loading = false;
                    setTimeout(() => {
                        this.eventsSubject.next();
                    }, 200);
                } else {
                    console.error('No se encontró ningún dato');
                }
            },
            error: (error) => {
                this.loading = true;
                console.log('Error al llamar el servicio', error);
            },
        });
    }


    getMoneda_Api(): void {
        this._monedaService.get().subscribe({
            next: (response: any) => {
                const response_data = response.data;
                if (response_data) {
                    this.monedas = response_data;
                    setTimeout(() => {
                        this.eventsSubject.next();
                    }, 200);
                } else {
                    console.error('No se encontraron datos');
                }
            },
            error: (error) => {
                console.log("Error al consumir el servicio", error);
            }
        });

    }



    Get_Desembolso(): void {
        this._Desembolso_Service.getByid(this.desembolso.id_desembolso).subscribe({
            next: (response: any) => {
                if (response.data) {
                    this.desembolso = response.data;
                } else {
                    console.log('No se encontraron datos.');
                }
            }
        })
    }


    stateOptions = [
        { label: 'No', value: 'NO' },
        { label: 'Sí', value: 'SI' },
    ];
    incluir_proveedor: string = 'NO';

    suscription: Subscription;
    almacenes_origen: any[] = [];
    almacenes_destino: any[] = [];
    categorias_dinamico: any[][] = [];
    proveedores: any[] = [];
    productos_dinamico: any[] = [];
    loading: boolean = true;
    monedas: any[] = [];
    sede: any = {};

    empresa: any = {};
    area: any = {};

    /* Enviar datos del TS AL HTML (DB) */
    productos_dinamico__db: any[][] = [];
    almacen_productos_dinamico__db: any[] = []; // array de pobjetos


    //filas que agregar capturar los campos
    detalles_liquidacion_gastos: any[] = [];

    //agregar filas en el dom de la tabla
    agregarFila() {
        const nuevaFila = {
            monto: 0
        };

        this.detalles_liquidacion_gastos.push(nuevaFila);
    }
    //elimina una fila depende al index de la tabla en este caso elimina 1
    eliminarFila(index: number) {
        this.detalles_liquidacion_gastos.splice(index, 1);
        this.Calculate_GastoTotal();
    }

    Calculate_GastoTotal(): void {
        console.log('calculando');
        this.desembolso.gasto_desembolso = 0;

        this.detalles_liquidacion_gastos.forEach(detalle => {
            this.desembolso.gasto_desembolso += parseFloat(detalle.monto);
        });
        this.desembolso.saldo_desembolso = this.desembolso.monto - this.desembolso.gasto_desembolso;
    }

    registerLiquidacionGastos(): void {

        const request = {
            desembolso_id: this.desembolso.id_desembolso,
            monto_desembolso: this.desembolso.monto_desembolso,
            gasto_desembolso: this.desembolso.gasto_desembolso,
            saldo_desembolso: this.desembolso.saldo_desembolso,
            detalles: this.desembolso.detalles,

            detalles_liquidacion_gasto: JSON.parse(JSON.stringify(this.detalles_liquidacion_gastos)),

        };
        console.log(request);

        this._liquidacion_gastos_service.create(JSON.parse(JSON.stringify(request))).subscribe({
            next: (response: any) => {

                if (response.status == 'CREATED') {
                    alert_success('Registrado de forma correcta', 5000);
                    this.registrosAgregado.emit(response.data); // Emite el nuevo registro

                } else {
                    this.registrosAgregado.emit(response.data); // Emite el nuevo registro
                    alert_error('ERROR', 'Ocurrió un error al consumir el servicio');
                }
            },
            error: (err) => {
                this.disableButton = false;
            },
        });
        this.Close_Modal_Event3.emit();
    }

    hideDialog() {
        this.Close_Modal_Event3.emit();

    }

}
