<div class="card flex gap-2" id="carta_1">
	<div class="w-9 mb-5">
		<div class="px-gutter pt-6 pb-20 bg-slate-900 flex-none">
			<div class="flex items-center justify-between" vexContainer>
				<h2 class="headline text-neutral-100 m-0 flex items-center w-full max-w-xxxl mr-6">
					<fa-icon [icon]="faTableList" class="hidden sm:block ml-5 fa-2xl"></fa-icon>
					<span class="ml-4 block pt-0">Caja </span>
				</h2>
			</div>
		</div>
	</div>
	<div class="container-fluid">

		<p-button  label="Registrar Caja"
			(click)="Open_Modal_RegistrarCaja()"></p-button>
		<div class="card text-dark bg-light mb-3">
			<div class="card-body">
				<p-table #dt2 [value]="req_Pago_Response" dataKey="id" [rows]="10" [rowsPerPageOptions]="[10, 25, 50]"
					[paginator]="true" [globalFilterFields]="['nombre_empresa',  'nombre_sede', 'nombre']">
					<ng-template pTemplate="caption">
						<div class="flex">
							<p-iconField iconPosition="left" class="ml-auto">
								<p-inputIcon>
									<i class="pi pi-search"></i>
								</p-inputIcon>
								<input pInputText #textInput type="text"
									(input)="dt2.filterGlobal(textInput.value, 'contains')" placeholder="Buscar">
							</p-iconField>
						</div>
					</ng-template>

					<ng-template pTemplate="header">
						<tr>
							<th *ngFor="let col of cols">{{ col.header }}</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-RowData>
						<tr>
							<td *ngFor="let col of cols">
								{{ RowData[col.field] }}
							</td>
							<td>
								<button pButton type="button" label="Ver"
									(click)="Open_Modal_DetallesCaja(RowData)"></button>
							</td>
						</tr>
					</ng-template>
				</p-table>

			</div>
		</div>
	</div>

	<!-- Modals -->
	<p-dialog header="Registrar Caja" [(visible)]="Display_Modal_RegistrarCaja" [draggable]="false" [resizable]="false"
		[style]="{width: '1200px', height: '500px', maxWidth: '90vw', maxHeight: '80vh'}" [closable]="false"
		[modal]="true" (onHide)="hideDialog() ">
		<app-registrar-caja (Close_Modal_RegistrarCaja_Event)="Close_Modal()"></app-registrar-caja>
	</p-dialog>
	<p-dialog header="Detalles de caja" [draggable]="false" [resizable]="false"
		[style]="{width: '1500px', height: '1500px', maxWidth: '150vw', maxHeight: '90vh'}" [closable]="false"
		[(visible)]="Display_Modal_DetallesCaja" [modal]="true">
		<app-detalles-caja (Close_Modal_Detalles1Caja_Event)="Close_Modal_DetallesCaja()"
			[RowData_Parent]="RowData"></app-detalles-caja>
	</p-dialog>
	<ng-template #ConfigUser></ng-template>
</div>