import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { DatetimeHelper } from 'src/app/_core/helpers/datetime.helper';
import { CommonService } from 'src/app/_core/services/common.service';
import { AdminRoutes } from 'src/app/admin/admin.routes';
import { AppRoutes } from 'src/app/app.routes';
import { pageTransition } from 'src/app/shared/utils/animations';
import { Images } from 'src/assets/data/images';
import { AlertType } from '../../../shared/components/alert/alert.type';
import { PublicRoutes } from '../../public.routes';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { LoginRequest } from 'src/app/request/login/login-request';
import { SessionService } from 'src/app/services/session.service';
import { LoginResponse } from 'src/app/response/login/login-response';
import { alert_warning } from 'src/app/functions/alert-function';
import { LoginService } from 'src/app/services/util/login.service';

@Component({
  selector: 'app-signin',
  standalone: true,
  imports: [InputTextModule, CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './signin.component.html',
  providers: [LoginService],
  styleUrls: ['./signin.component.css'],
  animations: [pageTransition],
})
export class SigninComponent implements OnInit {

  form: FormGroup;
  isSignDivVisiable: boolean = true;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private _router: Router,
    private fb: FormBuilder,
    private _loginService: LoginService,
    private _sessionService: SessionService,
  ) { }

  user: any = {};

  ngOnInit(): void {
    
  }


  login() {
    this._loginService.create(JSON.parse(JSON.stringify(this.user))).subscribe({
      next: ( response : any ) => {
      
       
        if ( response.code == 200 ) {
          const almacenes = (response.data && response.data.almacenes) ? response.data.almacenes : null;

          const user_id = (response.data)?response.data.usuario.id:null;
          const token = (response.data)?response.data.token:null;
          const usuario = (response.data)?response.data.usuario:null;
          const id_almacen = (response.data)?response.data.id_almacen:null;
          const user = usuario.user;
          const apellido_paterno = usuario.apellido_paterno;
          const apellido_materno = usuario.apellido_materno;
          const nombres = usuario.nombres;
          localStorage.setItem('id_usuario', user_id);
          localStorage.setItem('almacenes', JSON.stringify(almacenes));
          localStorage.setItem('id_almacen',id_almacen);
          localStorage.setItem('token', token);
          localStorage.setItem('user', user);
          localStorage.setItem('apellido_paterno', apellido_paterno);
          localStorage.setItem('apellido_materno', apellido_materno);
          localStorage.setItem('nombres', nombres);
          this.router.navigate(['/admin/proceso/proceso_venta']);
        } else {
          alert_warning('Usuario/Contraseña Incorrecta');
        }
      }
    });
  }

  goToDashboard() {
    setTimeout(() => {
      this._router.navigate(["admin/dashboard"]);
    }, 100);
  }

}








