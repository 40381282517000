<span class="text-2xl block mb-2 mt-4">
    
    ¿Estás seguro de cambiar el estado a <span class="font-bold"> {{DataComponent.estado}} </span>?
    <br>
    <input type="text" pInputText [(ngModel)]="DataComponent.detalles_estado">
</span>
<div class="flex w-100 align-items-end">
    <p-button label="Sí" severity="success" (onClick)="update__estado()" class="mr-5"></p-button>
    <p-button label="No" severity="danger" (onClick)="Close_Modal_f()"></p-button>
</div>


