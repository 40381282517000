<div class="modal-header">
    <h4 id="h41" class="modal-title pull-left">{{ "Registrar Cuenta" }}</h4>
</div>

<p-card>
    <form [formGroup]="form">
        <div class="row">
            <div class="col-lg-3">
                <span class="p-float-label">
                    <p-dropdown [showClear]="true" [options]="empresas" optionValue="id_empresa" optionLabel="nombre"
                        formControlName="empresa_id" (onChange)="Get_SedesByEmpresaId_Api($event)"></p-dropdown>
                    <label>Empresa</label>
                    <small *ngIf="form.get('empresa_id')?.invalid && (form.get('empresa_id')?.dirty || form.get('empresa_id')?.touched)" class="p-error">
                        {{ getErrorMessage('empresa_id') }}
                      </small>
                </span>
            </div>

            <div class="col-lg-3">
                <span class="p-float-label">
                    <p-dropdown [showClear]="true" [options]="sedes" optionValue="id_sede" optionLabel="nombre"
                        formControlName="sede_id" (onChange)="Get_AreasBySedeId_Api($event)"></p-dropdown>
                    <label>Sede</label>
                    <small *ngIf="form.get('sede_id')?.invalid && (form.get('sede_id')?.dirty || form.get('sede_id')?.touched)" class="p-error">
                        {{ getErrorMessage('sede_id') }}
                      </small>
                </span>
            </div>

            <div class="col-lg-3">
                <span class="p-float-label">
                    <p-dropdown [showClear]="true" placeholder="Selecciona Persona" [options]="personas"
                        optionValue="id_persona" optionLabel="nombres" formControlName="persona_id"></p-dropdown>
                    <label>Persona</label>
                  
                    <small *ngIf="form.get('persona_id')?.invalid && (form.get('persona_id')?.dirty || form.get('persona_id')?.touched)" class="p-error">
                        {{ getErrorMessage('persona_id') }}
                      </small>
                </span>
            </div>

            <div class="col-lg-3">
                <span class="p-float-label">
                    <input pInputText formControlName="nombre" />
                    <label>Persona</label>
                    <small *ngIf="form.get('nombre')?.invalid && (form.get('nombre')?.dirty || form.get('nombre')?.touched)" class="p-error">
                      {{ getErrorMessage('nombre') }}
                    </small>
                    
                </span>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-lg-3">
                <span class="p-float-label">
                    <input pInputText id="nombre_corto__id" class="form-control" formControlName="nombre_corto">
                    <label for="nombre_corto__id">Nombre Corto</label>
                    <small *ngIf="form.get('nombre_corto')?.invalid && (form.get('nombre_corto')?.dirty || form.get('nombre_corto')?.touched)" class="p-error">
                        {{ getErrorMessage('nombre_corto') }}
                      </small>
                </span>
            </div>

            <div class="col-lg-3">
                <span class="p-float-label">
                    <input pInputText id="nombre_largo__id" class="form-control" formControlName="nombre_largo">
                    <label for="nombre_largo__id">Nombre Largo</label>
                    <small *ngIf="form.get('nombre_largo')?.invalid && (form.get('nombre_largo')?.dirty || form.get('nombre_largo')?.touched)" class="p-error">
                        {{ getErrorMessage('nombre_largo') }}
                      </small>
                </span>
            </div>

            <div class="col-lg-3">
                <span class="p-float-label">
                    <input pInputText id="titular__id" class="form-control" formControlName="titular">
                    <label for="titular__id">Titular</label>
                    <small *ngIf="form.get('titular')?.invalid && (form.get('titular')?.dirty || form.get('titular')?.touched)" class="p-error">
                        {{ getErrorMessage('titular') }}
                      </small>
                </span>
            </div>

            <div class="col-lg-3">
                <span class="p-float-label">
                    <p-dropdown [showClear]="true" [options]="tipoCuentas" optionValue="id" optionLabel="nombre"
                        formControlName="tipo_cuenta"></p-dropdown>
                    <label for="tipo_cuenta">Tipo Cuenta</label>
                    <small *ngIf="form.get('tipo_cuenta')?.invalid && (form.get('tipo_cuenta')?.dirty || form.get('tipo_cuenta')?.touched)" class="p-error">
                        {{ getErrorMessage('tipo_cuenta') }}
                      </small>
                </span>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-lg-3">
                <span class="p-float-label">
                    <p-dropdown [showClear]="true" [options]="bancos" optionValue="id_banco" optionLabel="nombre"
                        formControlName="banco"></p-dropdown>
                    <label for="banco">Banco</label>
                    <small *ngIf="form.get('banco')?.invalid && (form.get('tipo_bancocuenta')?.dirty || form.get('banco')?.touched)" class="p-error">
                        {{ getErrorMessage('banco') }}
                      </small>
                </span>
            </div>

            <div class="col-lg-3">
                <span class="p-float-label">
                    <p-dropdown [showClear]="true" class="custom-dropdown" placeholder=" " [options]="monedas"
                        optionValue="id_moneda" optionLabel="simbolo" formControlName="simbolo_moneda"></p-dropdown>
                    <label>S/ Moneda</label>
                    <small *ngIf="form.get('simbolo_moneda')?.invalid && (form.get('simbolo_moneda')?.dirty || form.get('simbolo_moneda')?.touched)" class="p-error">
                        {{ getErrorMessage('simbolo_moneda') }}
                      </small>
                </span>
            </div>

            <div class="col-lg-3">
                <span class="p-float-label">
                    <p-dropdown [showClear]="true" [options]="monedas" optionValue="id_moneda" optionLabel="nombre"
                        formControlName="moneda"></p-dropdown>
                    <label for="moneda">Moneda</label>
                    <small *ngIf="form.get('moneda')?.invalid && (form.get('moneda')?.dirty || form.get('moneda')?.touched)" class="p-error">
                        {{ getErrorMessage('moneda') }}
                      </small>
                </span>
            </div>

            <div class="col-lg-3">
                <span class="p-float-label">
                    <input pInputText formControlName="nro_cuenta" pattern="^\d+(\.\d{2,})?$"
                        title="Debe ser un número con al menos dos decimales" />
                    <label for="nro_cuenta">Nº Cuenta</label>
                    <small *ngIf="form.get('nro_cuenta')?.invalid && (form.get('nro_cuenta')?.dirty || form.get('nro_cuenta')?.touched)" class="p-error">
                        {{ getErrorMessage('nro_cuenta') }}
                      </small>
                </span>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-lg-3">
                <span class="p-float-label">
                    <input pInputText id="nro_cci__id" class="form-control" formControlName="nro_cci"
                        pattern="^\d+(\.\d{2,})?$" title="Debe ser un número con al menos dos decimales" />
                    <label for="nro_cci__id">CCI Cuenta</label>
                    <small *ngIf="form.get('nro_cci')?.invalid && (form.get('nro_cci')?.dirty || form.get('nro_cci')?.touched)" class="p-error">
                        {{ getErrorMessage('nro_cci') }}
                      </small>
                </span>
            </div>

            <div class="col-lg-3">
                <span class="p-float-label">
                    <input pInputText id="monto_inicial__id" class="form-control" formControlName="monto_inicial"
                        title="Debe ser un número con al menos dos decimales" />
                    <label for="monto_inicial__id">Monto Inicial</label>
                    <small *ngIf="form.get('monto_inicial')?.invalid && (form.get('monto_inicial')?.dirty || form.get('monto_inicial')?.touched)" class="p-error">
                        {{ getErrorMessage('monto_inicial') }}
                      </small>
                </span>
            </div>

            <div class="col-lg-3">
                <span class="p-float-label">
                    <input pInputText id="detalles__id" class="form-control" formControlName="detalles"
                        title="Debe ser un número con al menos dos decimales" />
                    <label for="detalles__id">Detalles</label>
                    <small *ngIf="form.get('detalles')?.invalid && (form.get('detalles')?.dirty || form.get('detalles')?.touched)" class="p-error">
                        {{ getErrorMessage('detalles') }}
                      </small>
                </span>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-lg-12 text-end">
                <div class="register-botton">
                    <button pButton type="button" class="p-button-success ml-1" icon="pi pi-save" label="Registrar"
                        (click)="registerCuentaBan()"></button>
                    <p-button label="Cancelar" (click)="hideDialog()" [raised]="true" severity="danger"></p-button>
                </div>
            </div>
        </div>
    </form>
</p-card>
