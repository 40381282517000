<header class="bg-slate-50 shadow fixed top-0 w-full z-50 h-16">
   <nav class="container flex  items-center justify-between px-6 py-4 lg:px-8" aria-label="Global">
      <div class="flex lg:flex-1">
         <a href="#" class="-m-1.5 p-1.5">
            <span class="sr-only">Your Company</span>
            <img class="h-8 w-auto" src={{mainLogo}} alt="">
         </a>
      </div>
      <div class="flex lg:hidden">
         <button type="button" class="navbar-toggler">
            <span class="sr-only">Open main menu</span>
            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
               aria-hidden="true">
               <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
         </button>
      </div>
      <div class="hidden lg:flex lg:gap-x-12">
         <a [routerLink]="commonService.prepareRoute(publicRoutes.Home)"
            class="text-sm font-semibold leading-6 text-gray-900">Home</a>
         <a [routerLink]="commonService.prepareRoute(appRoutes.Admin, adminRoutes.Dashboard)"
            class="text-sm font-semibold leading-6 text-gray-900">Dashboard</a>
      </div>
      <div class="hidden lg:flex lg:flex-1 lg:justify-end">

         <a [routerLink]="commonService.prepareRoute(publicRoutes.Signup)"
            class="text-sm font-semibold leading-6 text-gray-900 mr-4">Signup</a>
         <a [routerLink]="commonService.prepareRoute(publicRoutes.Signin)"
            class="text-sm font-semibold leading-6 text-gray-900">Log in <span aria-hidden="true">&rarr;</span></a>
      </div>
   </nav>
   <!-- Mobile menu, show/hide based on menu open state. -->
   <div class="lg:hidden" role="dialog" aria-modal="true">
      <!-- Background backdrop, show/hide based on slide-over state. -->
      <div class="fixed inset-0 z-10"></div>
      <div
         class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
         <div class="flex items-center justify-between">
            <a href="#" class="-m-1.5 p-1.5">
               <span class="sr-only">Your Company</span>
               <img class="h-8 w-auto" src={{mainLogo}} alt="">
            </a>
            <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700">
               <span class="sr-only">Close menu</span>
               <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                  aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
               </svg>
            </button>
         </div>
         <div class="mt-6 flow-root">
            <div class="-my-6 divide-y divide-gray-500/10">
               <div class="space-y-2 py-6">
                  <a href="#" class="">
                     Features
                  </a>
                  <a href="#" class="nav-link">
                     Marketplace
                  </a>
                  <a href="#" class="nav-link">
                     Company
                  </a>
               </div>
               <div class="py-6">
                  <a href="#" class="nav-link">Login
                  </a>
               </div>
            </div>
         </div>
      </div>
   </div>
</header>