import { Component, ElementRef, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Images } from 'src/assets/data/images';
import { faTshirt } from '@fortawesome/free-solid-svg-icons';
import { LoginService } from 'src/app/services/util/login.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',

  styleUrls: ['./header.component.css'],
})
export class HeaderComponent {
    items: MenuItem[] | undefined;

    constructor(private router: Router,private loginService: LoginService) {}


    user : any =  localStorage.getItem('user');
    apellido_paterno : any =  localStorage.getItem('apellido_paterno');
    apellido_materno : any =  localStorage.getItem('apellido_materno');
    nombres : any =  localStorage.getItem('nombres');


    ngOnInit() {

        this.items = [
            // {
            //     label: 'Dashboard',
            //     icon: 'pi pi-fw pi-file',
            //     command: () => this.router.navigate(['/admin/dashboard'])
            // },
            {
                label: 'Gestión Desembolso',
                icon: 'pi pi-fw pi-wallet',
                items: [
                    {
                        label: 'Reporte desembolso',
                        icon: 'pi pi-fw pi-pencil',
                        command: () => this.router.navigate(['/admin/desembolso/reporte-desembolso'])

                    },
                    {
                        label: 'Registrar Requerimiento Pago',
                        icon: 'pi pi-fw pi-pencil',
                        command: () => this.router.navigate(['/admin/desembolso/reporte-req-pago'])

                    },
                    {
                      label: 'Registrar Liquidación Gastos',
                      icon: 'pi pi-fw pi-pencil',
                      command: () => this.router.navigate(['/admin/desembolso/reporte-liquidacion-gastos'])

                  },

                    // ,
                    // {
                    //     label: 'Registro Venta',
                    //     icon: 'pi pi-fw pi-calendar-times',
                    //     command: () => this.router.navigate(['/admin/proceso/Registro_Venta'])

                    // }
                ]
            },

            {
                label: ' Gestiòn de Caja',
                icon: 'pi pi-fw pi-briefcase',
                items: [
                    // {
                    //     label: 'Registro Cuenta',
                    //     icon: 'pi pi-fw pi-briefcase',

                    //     command: () => this.router.navigate(['/admin/cuenta/registrar-cuenta']),
                    //     styleClass: 'text-decoration-none'
                    // },

                    {
                        label: 'Reporte Caja',
                        icon: 'pi pi-fw pi-home',
                        command: () => this.router.navigate(['/admin/caja/reporte-caja']),
                        styleClass: 'text-decoration-none'
                    },


                ]
            },
            // {
            //     label: 'Proveedor',
            //     icon: 'pi pi-fw pi-truck',
            //     command: () => this.router.navigate(['/admin/proveedor'])

            // },
        
            {
                label: ' Gestiòn de Cuenta',
                icon: 'pi pi-fw pi-briefcase',
                items: [
                    // {
                    //     label: 'Registrar Caja',
                    //     icon: 'pi pi-fw pi-briefcase',

                    //     command: () => this.router.navigate(['/admin/caja/registrar-caja']),
                    //     styleClass: 'text-decoration-none'
                    // },
                    {
                        label: 'Reporte Cuenta',
                        icon: 'pi pi-fw pi-briefcase',

                        command: () => this.router.navigate(['/admin/cuenta/reporte-cuenta']),
                        styleClass: 'text-decoration-none'
                    }



                ]
            },

            // {
            //     label: 'Quit',
            //     icon: 'pi pi-fw pi-power-off'
            // }
        ];
    }
    logout(): void {
        this.loginService.logout();
        window.location.href = '/login';
      }
}
