import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { Subject } from 'rxjs';
import { alert_success, alert_warning } from 'src/app/functions/alert-function';
import { CuentaEmpresaService } from 'src/app/services/general-services/cuenta_by_empresa.service';
import { Empresa_Service } from 'src/app/services/general-services/empresa.service';
import { PeriodoService } from 'src/app/services/general-services/periodo.service';
import { Persona_Service } from 'src/app/services/general-services/persona.service';
import { Progrmacion_Desembolso_Service } from 'src/app/services/gestion-desembolso/programar-desembolso.service';

@Component({
  selector: 'app-programar-desembolso',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    DropdownModule,
    InputTextModule,
    ButtonModule,
    DialogModule,
    CalendarModule
  ],
  templateUrl: './programar-desembolso.component.html',
  styleUrl: './programar-desembolso.component.css',
})
export class ProgramarDesembolsoComponent {
  @Input() data: any; // Recibe los datos del componente padre
  @Output() Close_Modal_Event2 = new EventEmitter<void>();
  formData: any = {};
  selectedItem: any = null;
  cuenta: any = {};
  empresaSeleccionada: number | null = null;
  cuentaSeleccionada: number | null = null;
  p_desembolso: any = {};
  form: FormGroup;
  persona: any = {};
  requerimientos: any[] = [];
  desembolso : any []  = [];
  empresa_dinamico__db: any[][] = [];
  producto: any;
  eventsSubject: Subject<void> = new Subject<void>();

  empresa: any = {};
  constructor(
    private _programacionDesembolsoService: Progrmacion_Desembolso_Service,
    private _cuentaEmpresaService: CuentaEmpresaService,
    private _empresaService: Empresa_Service,
    private _periodoService :PeriodoService,
    private _peronsaService: Persona_Service,
  ) {}
  ngOnInit(): void {
    this.loadEmpresas();
    this.getPeriodo_Api();
    this.getPersonas_Api();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] && changes['data'].currentValue) {
      this.formData = { ...changes['data'].currentValue }; // Actualiza los datos del formulario
    }
  }

  loadEmpresas(): void {
    this._empresaService.get().subscribe({
      next: (response: any) => {
        if (Array.isArray(response.data)) {
          this.empresas = response.data;
        } else {
          console.error(
            'La respuesta de empresas no es un array:',
            response.data
          );
        }
      },
      error: (error) => {
        console.error('Error al cargar las empresas', error);
      },
    });
  }

  onEmpresaChange(event: DropdownChangeEvent): void {
    const empresaId = event.value;
    this.empresaSeleccionada = empresaId;

    if (empresaId) {
      this.loadCuentas(empresaId);
    } else {
      this.cuentas = [];
    }
  }

  loadCuentas(empresaId: number): void {
    this._cuentaEmpresaService.getByid(empresaId).subscribe({
      next: (response: any) => {
        if (Array.isArray(response.data)) {
          this.cuentas = response.data;
        } else {
          console.error(
            'La respuesta de cuentas no es un array:',
            response.data
          );
        }
      },
      error: (error) => {
        console.error('Error al cargar las cuentas', error);
      },
    });
  }

  onCuentaChange(event: DropdownChangeEvent): void {
    this.cuentaSeleccionada = event.value;
  }

  registerProgramacionDesembolso(): void {

    this.formData.persona_id__solicita = this.formData.persona_id;

    const request = {
      cuenta_id: this.empresaSeleccionada,
      empresa_id: this.empresaSeleccionada,
      periodo_id : this.p_desembolso.periodo_id,
      persona_id__autoriza : this.p_desembolso.id__persona__autorizada,
      detalles: this.p_desembolso.detalles,
      observaciones: this.p_desembolso.observaciones,
      
      fecha_programacion: this.p_desembolso.fecha_programacion,

      requerimientos_pago: [this.formData],
    };

    console.log(request);

    this._programacionDesembolsoService.create(request).subscribe({
      next: (response: any) => {;
        if (response.status == 'CREATED') {
          alert_success(response.message, 5000);
        } else {
          alert_warning(response.message);
        }
      },
      error: (err) => {
        //this.disableButton = false;
      },
    });
    this.Close_Modal_Event2.emit();
  }

  empresas: any[] = []; // Inicializa como array vacío
  cuentas: any[] = [];
  periodos : any [] = [];
  personas : any [] = [];

  getCuentaById_Api(cliente: DropdownChangeEvent): void {
    const id_cuenta = cliente.value;
    this._cuentaEmpresaService.getByid(id_cuenta).subscribe({
      next: (response: any) => {
        const response_data = response.data;
        if (response_data) {
          this.cuenta = response_data;
          // Inicializa filteredClientes con todas las opciones disponibles
          //this.filteredClientes = this.clientes;
          setTimeout(() => {
            this.eventsSubject.next();
          }, 100);
        } else {
          console.error('No se encontraron datos.');
        }
      },
      error: (error) => {
        console.log('Error al llamar el servicio. => ', error);
      },
    });
  }

  // Service method to get accounts by selected company
  getEmpresa_Cuenta(event: DropdownChangeEvent): void {
    const empresaId = event.value;

    this._empresaService.getByOther({ empresa: empresaId }).subscribe({
      next: (response: any) => {
        const response_data = response.data;
        if (response_data) {
          this.cuentas = response_data; // Asume que `cuentas` es el array que usas para el dropdown de cuentas
        } else {
          console.error(
            'No se encontraron cuentas para la empresa seleccionada.'
          );
        }
      },
      error: (error) => {
        console.error('Error al llamar el servicio', error);
      },
    });
  }

  // Service method to get account details by selected account
  getEmpresa_CuentaById_Api(event: DropdownChangeEvent): void {
    const cuentaId = event.value;

    this._cuentaEmpresaService.getByid(cuentaId).subscribe({
      next: (response: any) => {
        const response_data = response.data;
        if (response_data) {
          // Maneja la respuesta según sea necesario
        } else {
          console.error(
            'No se encontró información para la cuenta seleccionada.'
          );
        }
      },
      error: (error) => {
        console.error('Error al llamar el servicio', error);
      },
    });
  }
  getPeriodo_Api(): void{

    this._periodoService.get().subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.periodos = response_data;
					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontró un array de productos en la respuesta.');
				}
			},
			error: (error) => {
				console.log("Error al llamar el servicio", error);
			}
		});

  }
  getPersonas_Api(): void{

    this._peronsaService.get().subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.personas = response_data;
					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontró un array de productos en la respuesta.');
				}
			},
			error: (error) => {
				console.log("Error al llamar el servicio", error);
			}
		});

  }
  hideDialog() {
  //  this.form.reset();
		this.Close_Modal_Event2.emit();

	}
}
