import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { PeriodoService } from 'src/app/services/general-services/periodo.service';
import { Cuenta_Service } from 'src/app/services/gestion-cuenta/gestion-cuenta.service';
import { MovimientoCuenta_Service } from 'src/app/services/gestion-cuenta/movimiento_cuenta.service';

@Component({
  selector: 'app-resumen-cuenta-periodo',
  standalone: true,
  imports: [
    TableModule, DropdownModule, CommonModule, FormsModule
  ],
  templateUrl: './resumen-cuenta-periodo.component.html',
  styleUrl: './resumen-cuenta-periodo.component.css',
  providers: [
    MovimientoCuenta_Service
  ]
})
export class ResumenCuentaPeriodoComponent {

  @Input() Data_Parent: any;
  Data : any = {};
  Cuenta: any = {};
  id_periodo: number;
  Periodos: any = [];

  

  constructor (
    private _Cuenta_Service: Cuenta_Service,
    private _Periodo_Service: PeriodoService
  ) {}

  
  ngOnChanges(changes: SimpleChanges) {
		if (changes['Data_Parent'] && changes['Data_Parent'].currentValue) {
			this.Data = { ...changes['Data_Parent'].currentValue };
		}
	}

  
  ngOnInit():void {
    this.Get_Periodos_Api();
  }
  Get_Movimientos_By_Periodo ():void {
    console.log('data_parent => ' + this.Data_Parent.id_cuenta)
		const request = {
			'resumen-cuenta': this.Data.id_cuenta,
			'periodo': this.id_periodo
		}
		this._Cuenta_Service.getByOther(request, 'general/').subscribe({
			next: (response:any) => {
				if (response.status == 'OK') {
					this.Cuenta = response.data;
				} else {
					console.log('Error al consumir el servicio');
				}
			}
		})
	}

  Get_Periodos_Api(): void {
		this._Periodo_Service.get().subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.Periodos = response_data;
				} else {
					console.error('No se enconrtó datos');
				}
			},
			error: (error) => {
				console.log("Error al consumir el servicio", error);
			}
		});

	}

}
