<div class="row">
    <div class="col-lg-12">
        <p-card>
            <div class="row mb-5">
                <div class="col-lg-2">
                    <p-floatLabel>
                        <input pInputText [(ngModel)]="formData.nombre_emoresa" [disabled]="true">
                        <label>Empresa </label>
                    </p-floatLabel>
                </div>
                <div class="col-lg-2">
                    <p-floatLabel>
                        <input pInputText [(ngModel)]="formData.nombre_persona" [disabled]="true">
                        <label>Responsable</label>
                    </p-floatLabel>
                </div>
                <div class="col-lg-2">
                    <p-floatLabel>
                        <input pInputText [(ngModel)]="formData.nombre" [disabled]="true">
                        <label>Nombre </label>
                    </p-floatLabel>
                </div>

                <div class="col-lg-2">
                    <p-floatLabel>
                        <input pInputText [(ngModel)]="formData.nombre_corto" [disabled]="true">
                        <label>Nombre Corto</label>
                    </p-floatLabel>
                </div>
                <div class="col-lg-2">
                    <p-floatLabel>
                        <input pInputText [(ngModel)]="formData.detalles" [disabled]="true">
                        <label>Detalles</label>
                    </p-floatLabel>
                </div>
                <div class="col-lg-2">
                    <p-floatLabel>
                        <input pInputText [(ngModel)]="formData.tipo_cuenta" [disabled]="true">
                        <label>Tipo Cuenta</label>
                    </p-floatLabel>
                </div>
            </div>
            <div class="row mb-5">
                <div class="col-lg-2">
                    <p-floatLabel>
                        <input pInputText [(ngModel)]="formData.banco" [disabled]="true">
                        <label>Banco</label>
                    </p-floatLabel>
                </div>
                <div class="col-lg-2">
                    <p-floatLabel>
                        <input pInputText [(ngModel)]="formData.moneda" [disabled]="true">
                        <label>Moneda</label>
                    </p-floatLabel>
                </div>
                <div class="col-lg-2">
                    <p-floatLabel>
                        <input pInputText [(ngModel)]="formData.nro_cuenta" [disabled]="true">
                        <label>Nº Cuenta</label>
                    </p-floatLabel>
                </div>
                <div class="col-lg-4">
                    <p-floatLabel>
                        <input pInputText class="w-100" [(ngModel)]="formData.nro_cci" [disabled]="true">
                        <label>CCI</label>
                    </p-floatLabel>
                </div>

            </div>
            <div class="row">
                <div class="col-lg-12">
                    <h4>Movimientos</h4>
                    <p-button pRipple severity="primary" class="mr-5 mb-5" label="Registrar Movimiento"
                        (click)="Open_Modal_Registrar_MovimientoCaja()"></p-button>
                    <label>
                        Periodo:
                        <p-dropdown [showClear]="true" [options]="Periodos" optionValue="id_periodo"
                            optionLabel="nombre" [(ngModel)]="id_periodo"
                            (onChange)="Get_Movimientos_By_Periodo()"></p-dropdown>
                    </label>
                    <p-button pRipple severity="primary" label="Ver resumen por periodo"
                        (click)="Open_Modal_Ver_ResumenCuenta(formData)"></p-button>
<!-- 
                    <p-button pRipple severity="primary" label="Cerrar"
                        (click)="Close_Modal()"></p-button> -->

                    <p-table [value]="Movimientos" [tableStyle]="{ 'min-width': '50rem' }"
                        styleClass="p-datatable-gridlines p-datatable-striped">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Tipo</th>
                                <th>Fecha</th>
                                <th>Periodo</th>
                                <th>Descripción</th>
                                <th>Moneda</th>
                                <th>Ingreso</th>
                                <th>Egreso</th>
                                <th>Saldo disponible</th>
                                <th>Tipo Pago</th>
                                <th>Método Pago</th>
                                <th>Tipo Comprobante</th>
                                <th>Comprobante</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-movimiento>
                            <tr>
                                <td>{{ movimiento.tipo }}</td>
                                <td>{{ movimiento.fecha }}</td>
                                <td>{{ movimiento.nombre_periodo }}</td>
                                <td>{{ movimiento.concepto }}</td>
                                <td>{{ movimiento.moneda }}</td>
                                <td>{{ ( movimiento.tipo == 'INGRESO' ) ? movimiento.monto : '' }}</td>
                                <td>{{ ( movimiento.tipo == 'EGRESO' ) ? movimiento.monto : '' }}</td>
                                <td>{{ movimiento.monto_disponible }}</td>
                                <td>{{ movimiento.tipo_pago }}</td>
                                <td>{{ movimiento.metodo_pago }}</td>
                                <td>{{ movimiento.tipo_comprobante }}</td>
                                <td>{{ movimiento.comprobante }}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </p-card>
    </div>
</div>
<p-dialog header="Registrar movimiento de Cuenta" [modal]="true" (onHide)="hideDialog2()" class="dialog-modal" [(visible)]="Display_Modal_Registrar_Cuenta">
    <app-registrar-movimiento-cuenta (Close_Modal_Event3)="Close_Modal()">
    </app-registrar-movimiento-cuenta>
</p-dialog>

<p-dialog header="" [modal]="true" (onHide)="hideDialog()" [draggable]="false" [(visible)]="Display_Modal_Ver_ResumenCuenta" class="ui-dialog-2">
    <app-resumen-cuenta-periodo [Data_Parent]="formData">
    </app-resumen-cuenta-periodo>
</p-dialog>
