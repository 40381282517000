/* Angular Components */
import { Component, EventEmitter, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { Subject } from 'rxjs';
import { alert_success, alert_warning } from 'src/app/functions/alert-function';

/* App Components */

/* App Services, Request, Response */
import { Moneda_Service } from 'src/app/services/general-services/moneda.service';
import { Caja_Service } from 'src/app/services/gestion-caja/gestion_caja.service';
import { MovimientoCaja_Service } from 'src/app/services/gestion-caja/movimiento_caja.service';
import { MovimientoCaja_Request } from 'src/app/request/gestion-caja/movimiento-caja-request';
import { CommonModule } from '@angular/common';
import { CardModule } from 'primeng/card';

import { DialogModule } from 'primeng/dialog';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

/* Component Config */
@Component({
  selector: 'app-registrar-movimiento-caja',
  standalone: true,
  imports: [
    FormsModule,
    ToastModule,
    ReactiveFormsModule,
    FloatLabelModule,
    DialogModule,
    CardModule,
    DropdownModule,
    CalendarModule,
    InputTextModule,
    CommonModule,
  ],
  templateUrl: './registrar-movimiento-caja.component.html',
  styleUrl: './registrar-movimiento-caja.component.css',
  providers: [Caja_Service, MessageService],
})

/* Component */
export class RegistrarMovimientoCajaComponent {
  /* Component Data */

  @Output() Close_Modal_RegistrarMovimientCaja_Event = new EventEmitter<any>();
  @Output() registroAgregado = new EventEmitter<any>();

  /* Data Forms Inputs */
  Cajas: any[] = [];
  form: FormGroup;
  errors: any = {};
  Tipos: any[] = [
    {
      id_tipo: 1,
      nombre: 'INGRESO',
    },
    {
      id_tipo: 2,
      nombre: 'EGRESO',
    },
  ];
  Monedas: any[] = [];
  TiposConcepto: any[] = [
    {
      id_tipo: 1,
      nombre: 'RRHH',
    },
    {
      id_tipo: 2,
      nombre: 'OTROS',
    },
  ];
  TiposPago: any[] = [
    {
      id_tipo_pago: 1,
      nombre: 'FÍSICO',
    },
    {
      id_tipo_pago: 2,
      nombre: 'DIGITAL',
    },
  ];
  MetodosPago: any[] = [
    {
      id_metodo_pago: 1,
      nombre: 'EFECTIVO',
    },
    {
      id_metodo_pago: 2,
      nombre: 'YAPE',
    },
    {
      id_metodo_pago: 3,
      nombre: 'TRANSFERENCIA',
    },
  ];
  TiposComprobante: any[] = [
    {
      id_tipo_comprobante: 1,
      nombre: 'VOUCHER',
    },
  ];

  MovimientoCaja_Request: MovimientoCaja_Request = new MovimientoCaja_Request();

  eventsSubject: Subject<void> = new Subject<void>();
  constructor(
    private _Caja_Service: Caja_Service,
    private fb: FormBuilder,
    private _Moneda_Service: Moneda_Service,
    private _MovimientoCaja_Service: MovimientoCaja_Service,
    private messageService: MessageService
  ) {
    this.form = this.fb.group({
      caja_id: [null, Validators.required],
      tipo: [null, Validators.required],
      fecha: ['', Validators.required],
      tipo_concepto: ['', Validators.required],
      concepto: ['', Validators.required],
      moneda: ['', Validators.required],
      simbolo_moneda: ['', Validators.required],
      monto: ['', Validators.required],
      tipo_pago: ['', Validators.required],
      metodo_pago: ['', Validators.required],

      tipo_comprobante: [''],
      comprobante: [''],
    });

    // Limpia los errores al cambiar el valor en los campos del formulario
    this.limpiar_validacion_escribir();
  }
  ngOnInit(): void {
    this.Get_Cajas_Api();
    this.Get_Monedas_Api();
  }

  Get_Cajas_Api(): void {
    this._Caja_Service.get().subscribe({
      next: (response: any) => {
        if (response.status == 'OK') {
          this.Cajas = response.data;
          setTimeout(() => {
            this.eventsSubject.next();
          }, 200);
        } else {
          console.error('No se encontró ningún dato');
        }
      },
      error: (error) => {
        console.log('Error al llamar el servicio', error);
      },
    });
  }

  Get_Monedas_Api(): void {
    this._Moneda_Service.get().subscribe({
      next: (response: any) => {
        if (response.status == 'OK') {
          this.Monedas = response.data;
          setTimeout(() => {
            this.eventsSubject.next();
          }, 200);
        } else {
          console.error('No se encontró ningún dato');
        }
      },
      error: (error) => {
        console.log('Error al llamar el servicio', error);
      },
    });
  }

  Registrar_MovimientoCaja(event: Event): void {
    this.errors = {};

    if (this.form.invalid) {
      this.errors = this.getFormValidationErrors();
      return;
    }

    this._MovimientoCaja_Service.create(this.form.value).subscribe({
      next: (response: any) => {
        if (response.status === 'OK') {
          // Mostrar mensaje de éxito usando Toast
          this.messageService.add({
            severity: 'success',
            summary: 'Éxito',
            detail: 'El movimiento se registró con éxito!',
            life: 6000,
          });

          // Limpiar el formulario
          this.form.reset();

          // Emitir evento para actualizar el estado
          this.eventsSubject.next();
          this.registroAgregado.emit(response.data);
          // Cerrar el modal después de 3 segundos
          setTimeout(() => {
            this.Close_Modal_RegistrarMovimientCaja_Event.emit();
          }, 2000);
        } else {
          // Mostrar mensaje de advertencia usando Toast
          this.messageService.add({
            severity: 'warn',
            summary: 'Advertencia',
            detail: 'No hay saldo en la caja',
          });
        }
      },
      error: (error) => {
        console.log('Error al llamar el servicio', error);
        // Mostrar mensaje de error usando Toast
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Ocurrió un error al procesar la solicitud',
        });
      },
    });
  }

  Close_Modal_RegistrarMovimientoCaja() {
    this.form.reset();
    this.Close_Modal_RegistrarMovimientCaja_Event.emit();
  }

  limpiar_validacion_escribir(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key)?.valueChanges.subscribe(() => {
        this.errors[key] = null; // Limpia el error cuando el usuario empieza a escribir
      });
    });
  }

  private getFormValidationErrors() {
    const controls = this.form.controls;
    const errors: any = {};
    for (const key in controls) {
      if (controls[key].invalid) {
        errors[key] = 'Este campo es requerido';
      }
    }
    return errors;
  }
}
