import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CrudService } from '../util/crud.service';
import { behaidorInterface } from '../interfaces/behaidor-interface';
import { urlConstants } from 'src/app/constants/url-constants';
import { Persona_Request } from 'src/app/request/generalRequest/persona-request';
import { Persona_Response } from 'src/app/response/general-response/persona-response';


@Injectable({
	providedIn: 'root',
})
export class Persona_Service
	extends CrudService<Persona_Request, Persona_Response>
	implements behaidorInterface<Persona_Response[]>
{
	private dataSource = new BehaviorSubject<Persona_Response[]>([]);

	constructor(protected http: HttpClient) {
		super(http, urlConstants.persona);
	}

	setValueBehaidor(value: Persona_Response[]): void {
		this.dataSource.next(value);
	}
	getValueBehaidorSubject() {
		return this.dataSource.getValue();
	}
	clearBehaidorSubject(): void {
		this.dataSource.next([]);
	}
}
