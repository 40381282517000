<!-- Información de la persona solicitada -->
<div class="info-row">
  <div class="p-field">
    <label>Responsable:</label>
    <input readonly pInputText [(ngModel)]="formData.persona__solicita" name="persona_solicita" class="full-width readonly-input"/>
  </div>
  <div class="p-field">
    <label>Empresa:</label>
    <input readonly pInputText [(ngModel)]="formData.nombre_empresa" name="nombre_empresa" class="full-width readonly-input"/>
  </div>
  <div class="p-field">
    <label>Sede:</label>
    <input readonly pInputText [(ngModel)]="formData.nombre_sede" name="nombre_sede" class="full-width readonly-input"/>
  </div>
  <div class="p-field">
    <label>Área:</label>
    <input readonly pInputText [(ngModel)]="formData.nombre_area" name="nombre_area" class="full-width readonly-input"/>
  </div>
</div>


<!-- Tabla para mostrar detalles de liquidación de gasto -->
<div *ngIf="detallesGastos.length > 0">
  <table class="full-width">
    <thead>
      <tr>
        <th>Fecha</th>
        <th>Documento</th>
        <th>Nº</th>
        <th>Concepto</th>
        <th>Monto</th>
        <th>Ver Comprobante</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let detalle of detallesGastos">
        <td>{{ detalle.fecha_comprobante }}</td>
        <td>{{ detalle.tipo_comprobante }}</td>
        <td>{{ detalle.nro_comprobante }}</td>
        <td>{{ detalle.concepto }}</td>
      
        <td>{{ detalle.monto }}</td>
        <td>
          <button pButton type="button" [raised]="true"  pTooltip="Ver Comprobante" 
          tooltipPosition="right"  class="p-button-warning " icon="pi pi-file-pdf" label=""
          (click)="verComprobante()"></button>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<!-- Sección de Totales -->
<!-- Sección de Totales -->
<div class="total-section">
  <h3>Total:</h3>
  <table>
    <tbody>
      <tr>
        <td><label for="monto_desembolso">Monto Desembolso:</label></td>
        <td>
          <input id="monto_desembolso" readonly pInputText [(ngModel)]="formData.monto_desembolso" name="monto_desembolso" class="input-pequeño readonly-input1"/>
        </td>
      </tr>
      <tr>
        <td><label for="saldo_desembolso">Saldo Desembolso:</label></td>
        <td>
          <input id="saldo_desembolso" readonly pInputText [(ngModel)]="formData.saldo_desembolso" name="saldo_desembolso" class="input-pequeño readonly-input"/>
        </td>
      </tr>
      <tr>
        <td><label for="otro_campo">Total Gastado:</label></td>
        <td>
          <input id="otro_campo" readonly pInputText [(ngModel)]="formData.otro_campo" name="otro_campo" class="input-pequeño readonly-input2"/>
        </td>
      </tr>
    </tbody>
  </table>
</div>




