<p-card>
<form [formGroup]="form">
<div class="row">
    
    <div class="col-lg-12">
        <div class="row mt-5 mb-5">
            <div class="col-lg-2">
                <span class="p-float-label">
                    <p-dropdown [options]="cuentas" optionLabel="nombre" optionValue="id_cuenta" formControlName="cuenta_id">
                    </p-dropdown>
                    <label>Cuenta</label>
                    <small *ngIf="form.get('cuenta_id')?.invalid && (form.get('cuenta_id')?.dirty || form.get('cuenta_id')?.touched)" class="p-error">
                        {{ getErrorMessage('cuenta_id') }}
                      </small>
                </span>
            </div>
            <div class="col-lg-2">
                <span class="p-float-label">
                    <p-dropdown [options]="Tipos" optionLabel="nombre" optionValue="nombre" formControlName="tipo">
                    </p-dropdown>
                    <label>Tipo</label>
                    <small *ngIf="form.get('tipo')?.invalid && (form.get('tipo')?.dirty || form.get('tipo')?.touched)" class="p-error">
                        {{ getErrorMessage('tipo') }}
                      </small>
                </span>
            </div>
        
            <div class="col-lg-2">
                <span class="p-float-label">
                    <input type="text" pInputText  formControlName="concepto" class="w-100">
                    <label>Concepto</label>
                    <small *ngIf="form.get('concepto')?.invalid && (form.get('concepto')?.dirty || form.get('concepto')?.touched)" class="p-error">
                        {{ getErrorMessage('concepto') }}
                      </small>
                </span>
            </div>

            <div class="col-lg-4">
                <span class="p-float-label">
                    <input type="text" pInputText formControlName="detalles" class="w-100">
                    <label>Detalles</label>
                    <small *ngIf="form.get('detalles')?.invalid && (form.get('detalles')?.dirty || form.get('detalles')?.touched)" class="p-error">
                        {{ getErrorMessage('detalles') }}
                      </small>
                </span>
            </div>
            <div class="col-lg-2">
                <span class="p-float-label">
                    <p-dropdown [options]="periodos" optionLabel="nombre" optionValue="id_periodo" formControlName="periodo_id"></p-dropdown>
                    <label>Periodo</label>
                    <small *ngIf="form.get('periodo_id')?.invalid && (form.get('periodo_id')?.dirty || form.get('periodo_id')?.touched)" class="p-error">
                        {{ getErrorMessage('periodo_id') }}
                      </small>
                </span>
            </div>
        </div>


        <div class="row mb-5">
            <div class="col-lg-2">
                <span class="p-float-label">
                    <p-dropdown [options]="Monedas" optionLabel="nombre" optionValue="nombre" formControlName="moneda">
                    </p-dropdown>
                    <label>Moneda</label>
                    <small *ngIf="form.get('moneda')?.invalid && (form.get('moneda')?.dirty || form.get('moneda')?.touched)" class="p-error">
                        {{ getErrorMessage('moneda') }}
                      </small>
                </span>
            </div>
            <div class="col-lg-2">
                <span class="p-float-label">
                    <p-dropdown [options]="Monedas" optionLabel="simbolo" optionValue="simbolo" formControlName="simbolo_moneda">
                    </p-dropdown>
                    <label>Símbolo Moneda</label>
                    <small *ngIf="form.get('simbolo_moneda')?.invalid && (form.get('simbolo_moneda')?.dirty || form.get('simbolo_moneda')?.touched)" class="p-error">
                        {{ getErrorMessage('simbolo_moneda') }}
                      </small>
              </span>
            </div>
            <div class="col-lg-2">
                <span class="p-float-label">
                    <input type="text" pInputText  formControlName="monto" class="w-100">
                    <label>Monto</label>
                    <small *ngIf="form.get('monto')?.invalid && (form.get('monto')?.dirty || form.get('monto')?.touched)" class="p-error">
                        {{ getErrorMessage('monto') }}
                      </small>
                </span>
            </div>
            <div class="col-lg-2">
                <span class="p-float-label">
                    <p-dropdown [options]="TiposPago" optionLabel="nombre" optionValue="nombre" formControlName="tipo_pago">
                    </p-dropdown>
                    <label>Tipo Pago</label>
                    <small *ngIf="form.get('tipo_pago')?.invalid && (form.get('tipo_pago')?.dirty || form.get('tipo_pago')?.touched)" class="p-error">
                        {{ getErrorMessage('tipo_pago') }}
                      </small>
                </span>
            </div>
            <div class="col-lg-2">
                <span class="p-float-label">
                    <p-dropdown [options]="MetodosPago" optionLabel="nombre" optionValue="nombre" formControlName="metodo_pago">
                    </p-dropdown>
                    <label>Método Pago</label>
                    <small *ngIf="form.get('metodo_pago')?.invalid && (form.get('metodo_pago')?.dirty || form.get('metodo_pago')?.touched)" class="p-error">
                        {{ getErrorMessage('metodo_pago') }}
                      </small>
                </span>
            </div>
            <div class="col-lg-2">
                <span class="p-float-label">
                    <p-dropdown [options]="TiposComprobante" optionLabel="nombre" optionValue="nombre" formControlName="tipo_comprobante" class="w-100">
                    </p-dropdown>
                    <label>Tipo comprobante</label>
                    <small *ngIf="form.get('tipo_comprobante')?.invalid && (form.get('tipo_comprobante')?.dirty || form.get('tipo_comprobante')?.touched)" class="p-error">
                        {{ getErrorMessage('tipo_comprobante') }}
                      </small>
                </span>
            </div>
        </div>
        <div class="row mb-5">
            <div class="col-lg-12">
                <span class="p-float-label">
                    <input type="text" pInputText formControlName="comprobante" class="w-100">
                    <label>Comprobante</label>
                    <small *ngIf="form.get('comprobante')?.invalid && (form.get('comprobante')?.dirty || form.get('comprobante')?.touched)" class="p-error">
                        {{ getErrorMessage('comprobante') }}
                      </small>
                </span>
            </div>
        </div>
       
        <div class="row">
            <div class="col-lg-12 text-end">
                <p-button icon="pi pi-iconName" label="Registrar"    (onClick)="Registrar_MovimientoCuenta()"></p-button>
                <p-button label="Cancelar" (click)="hideDialog()" [raised]="true" severity="danger"></p-button>
            </div>
        </div>
    </div>
</div>
</form></p-card>