import { Injectable } from '@angular/core';


import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';



import { CrudService } from '../util/crud.service';
import { behaidorInterface } from '../interfaces/behaidor-interface';
import { urlConstants } from 'src/app/constants/url-constants';


import { Empresa_Request } from 'src/app/request/generalRequest/empresa-request';
import { Empresa_Response } from 'src/app/response/general-response/empresa-response';
import { Moneda_Response } from 'src/app/response/general-response/moneda-response';
import { Moneda_Request } from 'src/app/request/generalRequest/moneda-request';
import { Tipo_Requerimiento_Request } from 'src/app/request/generalRequest/tipoRequerimiento-request';
import { Tipo_Requerimiento_Response } from 'src/app/response/general-response/tipoRequerimiento-response';


@Injectable({
	providedIn: 'root',
})
export class Tipo_Requerimiento_Service
	extends CrudService<Tipo_Requerimiento_Request, Tipo_Requerimiento_Response>
	implements behaidorInterface<Tipo_Requerimiento_Response[]>
{
	private dataSource = new BehaviorSubject<Tipo_Requerimiento_Response[]>([]);

	constructor(protected http: HttpClient) {
		super(http, urlConstants.tipoRequerimiento);
	}

	setValueBehaidor(value: Tipo_Requerimiento_Response[]): void {
		this.dataSource.next(value);
	}
	getValueBehaidorSubject() {
		return this.dataSource.getValue();
	}
	clearBehaidorSubject(): void {
		this.dataSource.next([]);
	}
}
